import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

//  router
 Vue.use(VueRouter)
 // ELSE?! kann routes raus? /logout adden?! mit fxhash news rein etc?!
 // /channel/ oder /c/
 const router = new VueRouter({ mode: 'history',
  routes: [
    { path: '/', component: App },
    { path: '/about', component: App },
    { path: '/contact', component: App },
    { path: '/explore', component: App },
    { path: '/explore/:id', component: App },
    { path: '/frames', component: App },
    { path: '/frame/:id', component: App },
    { path: '/add', component: App },
    { path: '/add/:id', component: App },
    { path: '/channel', component: App }
  ]
});

Vue.config.devtools = false
Vue.config.productionTip = false

if (module.hot) {
  module.hot.accept() // already had this init code

  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') console.clear()
  })
}

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
