import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// const VBtn = Vue.component('VBtn')
// VBtn.options.props.ripple.default = false

Vue.use(Vuetify);

export default new Vuetify({
});
