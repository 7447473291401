<template>
  <v-app id="inspire">

<!-- #frame 
 <template v-if="this.$route.path !=='/' && this.$route.path !== '/frames' && this.$route.path !=='/explore'  && this.$route.path !== '/about'  && this.$route.path !== '/home' ">
-->
<template v-if="this.$route.path.indexOf('/frame/') !== -1">
<!--
  TODO ENDE:
404 in cool


  && starts with tz || min 1 char?
= is showframe

#go 
-->



<v-toolbar v-if="!loadedEntries" color="black">
    <!--  <v-toolbar-title class="grey--text">DIYFRAME.XYZ <small>by Andre Fuchs</small></v-toolbar-title> -->





        <v-btn
      
  color="black"
      large
               outlined
                @click="openLink('https://diyframe.xyz/?ref=frametz')"
       
      >
      <!-- CREATE A DIYFRAME = zu gross -->
       <img height="50" class="mr-4" src="@/assets/logo.png" /><span class="grey--text headline">DIYFRAME</span></v-btn><small  @click="openLink('https://andrefuchs.xyz/?ref=frame')" class="grey--text subtitle">by Andre Fuchs</small>

       </v-toolbar >

<!--  @touchstart="mouseMove()" @touchmove="mouseMove() -->

      <v-container
      @mousemove="mouseMove()"
       @touchmove="mouseMove()"
        height="100vh"
        width="100vh"
        fluid
               class=" d-flex black ma-0 pa-0"
      >
      
      <v-card v-if="!loadedEntries"   dark color="black" width="600">
  
   <!-- 
  <v-card
           color="black"
            dark
 flat
          tile
    
          >
         
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
             
             
             <v-card-title class="text-h2 grey--text text--lighten-2">{{ formatPkhString(urlFrameUserWallet) }}</v-card-title>

<v-card-title class="text-h3 grey--text text--lighten-2">FRAME {{urlFrameID}}</v-card-title>
      <v-card-title class="text-h4 grey--text">LOADING...</v-card-title>


  <v-card-text >
          <v-progress-circular
       v-if="!loadedEntries"
      indeterminate
      color="grey"
    ></v-progress-circular>


     </v-card-text>

              </div>
            </div>

          </v-card>

-->
  </v-card>





        <!-- VIDEO  add controls :options="options -->
        <v-card
          v-if="loadedEntries && mimeType === 'video'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100vh"
        >
         
          <video
            id="video"
            ref="video"
            autoplay
            muted
            height="100%"
            width="100%"
            loop
            :src="filePath"
            @onload="hideLoading()"
            @error="videoError()"
          
          ></video>
        </v-card>
        <!--  @loadstart="showLoading()" -->

        <!-- #img lazy-src="@/assets/logo.png" -->
        <v-img
          v-if="loadedEntries && mimeType === 'image'"
         
          height="100vh"
          width="100vh"
          contain
          :src="filePath"
          @error="imageError()"
          @loadstart="showLoading()"
          @load="hideLoading()"
        >
        </v-img>

        <!--  mimeType === null || mimeType === 'XXXXXXXXXXXXXxvideo' -->
        <v-card
          v-if="loadedEntries && mimeType === 'application'"
          flat
          tile
          class="mx-auto black align-center justify-center ma-0 pa-0"
          height="100vh"
          width="100%"
        >
          <!-- #iframe onload="hideLoading()" -->
          <iframe
            id="interactiveX"
            loading="lazy"
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            allowTransparency="false"
            :src="filePath"
            @error="iframeError()"
            @loadstart="showLoading()"
            @load="hideLoading()"
          ></iframe>
        </v-card>
      


      </v-container>


<v-footer v-show="mouseMoving || !loadedEntries" fixed color="transparent">

  <v-card
  v-if="this.dataCurrentFrameToken === null"
  flat
    class="ml-2 rounded-lg"
    width="344"
    outlined
  >

  
  <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
      FRAME {{urlFrameID}}
        </v-list-item-title>

        <v-list-item-subtitle>Loading...</v-list-item-subtitle>

        
        <v-list-item-subtitle class="mt-4">{{ formatPkhString(urlFrameUserWallet) }}</v-list-item-subtitle>

  
 <v-list-item-title class="mt-2">
      <v-progress-circular
       v-if="!loadedEntries"
      indeterminate
      color="grey"
    ></v-progress-circular>
 </v-list-item-title>

      </v-list-item-content>


    </v-list-item>




  </v-card>

  <v-card
  v-if="this.dataCurrentFrameToken !== null"
  flat
    class="ml-2 rounded-lg"
    width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
          {{ this.dataCurrentFrameToken.name  }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="this.dataCurrentFrameToken.artist_profile !== null && this.dataCurrentFrameToken.artist_profile!== undefined">{{getUsername(this.dataCurrentFrameToken.artist_profile.alias,this.dataCurrentFrameToken.artist_address) }}</v-list-item-subtitle>

      </v-list-item-content>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        small
        @click="openLink('https://objkt.com/asset/'+dataCurrentFrameToken.fa2_address+'/'+dataCurrentFrameToken.token_id)"
      >
        Collect on Objkt.com
      </v-btn>
        <v-spacer></v-spacer>
<v-btn  @click="shareViaWebShare(openFrameURL(urlFrameID),'Check this DIYFRAME out')" text small outlined
        rounded><v-icon left>mdi-share-variant</v-icon> </v-btn>

    </v-card-actions>

  </v-card>


      <v-card
        flat
         color="transparent"
        width="100%"
      outlined
      >
   




 

   <v-card-actions>
    <!--
   <v-btn
      
  color="black"
        class="white"
       
       
        large
        
        outlined
        
        @click="openLink('https://diyframe.xyz/?ref=frame')"
       
      >
       <img height="30" class="mr-4" src="@/assets/logo.png" />DIYFRAME</v-btn>


       mdi-repeat-once
     
       mdi-camera-timer
-->

     
<!-- 
  SHOW DETAILS OF PIECE
 <v-btn>
          <v-icon>mdi-smart-card-outline</v-icon>
        </v-btn>
-->

<!--
  SPÄTER EINBAUEN

          <v-btn>
            show stats OGprice and/or floor, etc
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>


       

   

         
     
        <v-btn @click="volumeOn = false" v-if="volumeOn">
          <v-icon>mdi-volume-high</v-icon>
        </v-btn>
       <v-btn  @click="volumeOn = true" v-if="!volumeOn">
          <v-icon>mdi-volume-off</v-icon>
        </v-btn>

-->



       <v-btn 
        color="black"
        class="white"
            
               outlined
               @click="toggleFullScreen()">
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>


<!-- TODO IMAGE MIT REIN 
    <v-btn  @click="shareViaWebShare(openFrameURL(item[0].diyframe_frame_id),'Check out this DIYFRAME')">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>
-->




 <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
           
             color="black"
        class="ml-2 white"
            
               outlined
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
                   <v-icon>mdi-av-timer</v-icon>   
            </v-btn>
          </template>
          <span>Setup cycle interval between NFTs</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in itemsInterval"
          :key="index"
          @click.native="cycleInterval = (item.interval*1000)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

     <v-btn  color="black"
        class="white"
            
               outlined
               @click="nextPieceInFrame('prev')">
          <v-icon>mdi-skip-previous</v-icon>
        </v-btn>

       <v-btn  color="black"
        class="white"
            
               outlined
               @click="nextPieceInFrame('next')">
          <v-icon>mdi-skip-next</v-icon>
        </v-btn>

<v-spacer></v-spacer>

 <v-btn @click="openLink('https://diyframe.xyz/?ref=framebr')">
           <img height="30" src="@/assets/logo.png" /></v-btn>

   </v-card-actions>
      </v-card>
    </v-footer>

      <v-btn
        v-show="loadingInfo"
        color="black"
        fab
        dark
        small
        fixed
        bottom
        right
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-btn>
      
 </template>
<!-- #showframe -->

<!-- 
<template v-if="this.$route.path ==='/' && this.$route.path === '/frames' && this.$route.path ==='explore'  && this.$route.path === 'about'  && this.$route.path === 'home' ">
  -->
  
  <template v-else>


 <v-navigation-drawer
      v-model="drawer"
      app
      color="black"
      dark
    >
      <v-sheet
        color="black"
        class="pa-4"
      >
   
          <v-img src="@/assets/v-logo.png" class="mt-2 mb-2" contain height="60"></v-img>
     

        <div class="grey--text">{{ formatAddress(getPkh) }}</div>


      </v-sheet>

      <v-divider></v-divider>

      <v-list nav dark>
        <v-list-item
          v-for="(item, index) in links"
          :key="index"
          link
    @click="changeURL(item.title)"
    
    :to="item.link"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

 <v-list-item
            
              link
    @click="openLink('https://blog.diyframe.xyz/')"
        >

         


          <v-list-item-content>
            <v-list-item-title>Help</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

              <v-list-item
              v-if="!drawerRight"
              link
     @click.native="drawerRight = true, drawer = false"
        >



          <v-list-item-content>
            <v-list-item-title>Donate</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


 

       

<!-- bottom 
Help -> icon up
https://blog.diyframe.xyz/tag/get-started/
-->

      </v-list>


<template v-slot:append>

 


 <v-list-item
            
              link
    @click="toggleFullScreen()"
        >

           <v-list-item-icon>
            <v-icon>mdi-fullscreen</v-icon>
          </v-list-item-icon>


          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
 </template>
      
    </v-navigation-drawer>



    <v-navigation-drawer
    v-model="drawerRight"
      app
      clipped
      right
      dark
      color="black darken-4"
    >



     <v-card
   dark
   flat
       max-width="200"
    outlined
    color="black"
  >
    <v-list-item three-line>
      <v-list-item-content>
      
        <v-list-item-title class="text-h5 mb-1">
          DONATIONS
        </v-list-item-title>
       Your support via tez or NFT donations are gladly accepted.
      </v-list-item-content>

  
    </v-list-item>

    <v-card-actions>
     <v-btn depressed outlined class="mb-2 text-lowercase"
                  @click="openLink('https://tzkt.io/tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc/operations/')"
                  color="grey lighten-1">
                  diyframe.tez<v-icon right>mdi-arrow-top-right</v-icon>
                </v-btn>

                                 <v-tooltip v-model="showTooltipDonations" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed outlined class="mb-2 ml-1 custom-transform-class text-none" color="grey lighten-1"
                      @click="copyToClipboard('tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc')"
                      @click.native="showTooltipDonations =! showTooltipDonations">
                      
                      <v-icon   v-bind="attrs"
                      v-on="on">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <!-- Copied <v-icon left>mdi-clipboard-check-multiple-outline</v-icon> -->
                  <span> I really appreciate your
                    support. Thank
                    you! <i>Andre</i></span>
                </v-tooltip>


    </v-card-actions>

 <v-card-actions>
    <v-btn  class="text-lowercase" color="grey lighten-1" @click="openLink('https://twitter.com/intent/tweet?text=%40TezosCommons%0AI%20would%20like%20to%20nominate%20%40AndreFvchs%20for%20launching%20his%20free%20%23Tezos%20dApp%20diyframe.xyz%20on%20mainnet.%20%F0%9F%8F%85%20%20%0A%0A%23TezosCRP%20%23TezDevAward%20%23TheAssimilationAward')" dark depressed block outlined>
                <v-icon left>mdi-twitter</v-icon>Nominate
              </v-btn>
 </v-card-actions>
  </v-card>
               
<!--
  TODO 
if !delegator 
change to ARTERY


 <v-list-item link>
     <v-list-item-content>
    <v-list-item-title class="grey--text">diyframe.tez</v-list-item-title>
    </v-list-item-content>
    </v-list-item>
-->
    </v-navigation-drawer>


   <v-system-bar app class="white--text" color="#F52503">
            <span class="font-weight-bold" @click="openLink('https://andrefuchs.xyz/')">DIYFRAME by Andre Fuchs</span> 
            <v-spacer></v-spacer>
<span class="font-weight-bold">MAINNET ALPHA 0.1.0</span>
                  </v-system-bar>

    <v-app-bar
      app
      clipped-right
      flat
      height="72"
      color="black"
      dark
    >
         <!--
             <v-btn
              icon
              >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            -->
<v-app-bar-nav-icon @click="drawer =! drawer "></v-app-bar-nav-icon>

          <v-toolbar-title v-if="this.$route.path==='/'">HOME</v-toolbar-title>
           <v-toolbar-title v-else-if="this.$route.path==='/frames'">FRAMES</v-toolbar-title>
          <v-toolbar-title v-else-if="this.$route.path==='/explore'">EXPLORE</v-toolbar-title>
          <v-toolbar-title v-else-if="this.$route.path==='/about'">ABOUT</v-toolbar-title>
          <v-toolbar-title v-else-if="this.$route.path==='/add' || this.$route.path.indexOf('/add/') !== -1">ADD</v-toolbar-title>

 <v-spacer></v-spacer>
 <!--
 <v-progress-circular
     v-if="getLoading"
      indeterminate
      color="grey"
    ></v-progress-circular>
-->
  <v-btn depressed dark  v-if="!getConnected" @click="connect"><v-icon left>mdi-login-variant</v-icon> Connect Wallet</v-btn>

  <v-menu v-if="getConnected" offset-y left transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn  icon class="elevation-2" v-on="on">
        <v-badge
          color="success"
          dot
            :content="messages"
         :value="messages"
          bordered
          offset-x="10"
          offset-y="10"
        >
          <v-avatar size="40">
          <!--  <v-img src="https://andrefuchs.xyz/avatar.png"></v-img> -->

            <v-icon>mdi-account</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-list nav>
      <v-list-item
        v-for="(item, index) in menuUser"
        :key="index"
        :exact="item.exact"
        :disabled="item.disabled"
        link
      >

        <v-list-item-icon>
          <v-icon small :class="{ 'grey--text': item.disabled }">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content class="grey--text text--darken-2">
          <v-list-item-title >{{ formatAddress(getPkh) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

  <v-divider class="my-1"></v-divider>

      <v-list-item link @click="logoutUser">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  

  
    </v-app-bar>



   

    <v-main class="black">
     
      <!-- #home -->
   <template v-if="this.$route.path==='/'">
 
   
    <v-container class="black" fluid height="100%">







  <v-card
   flat
     width="444"
      class="mx-auto pb-4"
            color="black"
            
            
    >

       <v-card-title class="text-h5 white--text">
           SPOTLIGHT
            </v-card-title>
      
      <v-carousel
      v-if="arrayFeatured.length > 0"
                  class="black"
        :continuous="false"
        :cycle="cycle"
        :show-arrows="true"
        hide-delimiter-background
        delimiter-icon="mdi-circle"
        height="500"
      >
        <v-carousel-item
         v-for="(item,i) in arrayFeatured"
      :key="i"
    
        >
     
 <v-img
min-height="300"               
max-height="333" 
width="444"
contain 
:src="IPFStoHTTPbutiq(item)"     
lazy-src="@/assets/v-logo.png"
></v-img>
      
 
<v-list  three-line color="black" >
      <v-list-item>
        <!--
        <v-list-item-avatar>
          <v-img :src="item.avatar"></v-img>
        </v-list-item-avatar>
        -->
        <v-list-item-content class="white--text">

          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="grey--text text--lighten-2">{{ item.artist_profile.alias }}</v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"> 
                  
                  <v-btn  @click ="openDialogAddFrame(item.platform,item.token_id,item.fa2_address)" small color="white"  class="mt-2">
                    
         <v-icon
                 small
       left
       
      >
        mdi-plus
      </v-icon>    Add to Frame
              </v-btn> 
              
                 <v-btn  @click="openLink('https://objkt.com/asset/'+item.fa2_address+'/'+item.token_id)" small color="grey" outlined class="mt-2 ml-2">
                    
         <v-icon
                 small
       left
       
      >
       mdi-cart-outline
      </v-icon>    Collect
              </v-btn>



              </v-list-item-subtitle>
        </v-list-item-content>
      
      </v-list-item>
    </v-list>
        </v-carousel-item>
     
        
     </v-carousel>
        
      <!--
      <v-list three-line color="grey">
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://andrefuchs.xyz/avatar.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>item.name }}</v-list-item-title>
          <v-list-item-subtitle>Artwork Title</v-list-item-subtitle>
                <v-list-item-subtitle>Price / Platform / ETC</v-list-item-subtitle>
        </v-list-item-content>
      
      </v-list-item>
    </v-list>
      -->
       
      
   
    </v-card>
    
    <!--
      ! TODO query contract ask pif
   <v-card
                  flat
                   max-width="444"  class="mx-auto mt-4" 
            color="black"
            dark
          >
            <v-card-title class="text-h5">
           Recently Created Frames
            </v-card-title>

              
            
            <v-card-subtitle>xxx</v-card-subtitle>

       
            
            <v-card-actions>
              <v-btn  outlined >
                SUPPORT NOW
              </v-btn>
            </v-card-actions>
        
            
          </v-card>
  

      <v-card
                  flat
                   max-width="444"  class="mx-auto mt-4" 
            color="black"
            dark
          >
            <v-card-title class="text-h5">
           Curated Frames
            </v-card-title>

              
            
            <v-card-subtitle>by xyz / by sss / </v-card-subtitle>

       
            
            <v-card-actions>
              <v-btn  outlined >
                SUPPORT NOW
              </v-btn>
            </v-card-actions>
        
            
          </v-card>
  -->


<v-card 
              class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
     
<v-sheet
dark
  color="black"

>
              <v-card-title class="noWordBreak"><v-icon left>mdi-hand-coin-outline</v-icon> Earn Rewards</v-card-title>
<v-card-subtitle>Receive Tezos staking rewards</v-card-subtitle>

              <v-avatar
                class="ml-2"
                size="125"
                tile
              >
                <v-img src="@/assets/artery.png"></v-img>
              </v-avatar>

                
                 <v-card-subtitle> Delegate your staking rights to Artery - the Tezos bakery of DIYFRAME creator and generative NFT artist Andre Fuchs.</v-card-subtitle>
  
</v-sheet>


            <v-card-actions class="black">
              <v-btn @click="openLink('https://xtz.li/')" dark depressed block outlined>
                <v-icon left>mdi-cursor-pointer</v-icon>DELEGATE NOW WITH 2 CLICKS
              </v-btn>
            </v-card-actions>
          </v-card>


        
               
          
    <!-- !TODO adden   
 <v-card
                  flat
                   max-width="444"  class="mx-auto mt-4" 
            color="black"
            dark
          >
            <v-card-title class="text-h5">
         NEW 
            </v-card-title>
      
  
  <v-card  flat
                   max-width="444"  class="mx-auto  black" 
            color="black"
            >
   
        <v-tabs
          v-model="tabs"
          fixed-tabs
               
                   background-color="black"
                slider-color="black"
        >
          <v-tab
                  color="grey"
            v-for="n in itemsTabs"
            :key="n"
          >
           {{ n }}
          </v-tab>
        </v-tabs>
  </v-card>

    <v-tabs-items v-model="tabs">
      
        <v-tab-item>
        <v-card flat color="black" class="mb-4">
      <v-subheader>May</v-subheader>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="i in 6"
              :key="i"
              cols="4"
            >
              <img
                :src="`https://randomuser.me/api/portraits/men/${i + 20}.jpg`"
                alt="lorem"
                class="image"
                height="100%"
                width="100%"
              >
            </v-col>
          </v-row>
        </v-container>

        <v-subheader>June</v-subheader>
        <v-container fluid>
          <v-row>
            <v-col
              v-for="i in 6"
              :key="i"
              cols="4"
            >
              <img
                :src="`https://randomuser.me/api/portraits/women/${i + 5}.jpg`"
                alt="lorem"
                class="image"
                height="100%"
                width="100%"
              >
            </v-col>
          </v-row>
        </v-container>
         </v-card>
      </v-tab-item>
      
      <v-tab-item>
        <v-card flat color="black" class="mb-4">
          
  
     
  <v-row>
    <v-col
      v-for="n in 9"
      :key="n"
      class="d-flex child-flex"
      cols="4"
    >
      <v-img
        :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
        :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>

        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-title class="text-h5">
            An awesome title
          </v-card-title>
          <v-card-text>
            <p>
               me + friends + paid feature + launch on df


              Duis lobortis massa imperdiet quam. Donec vitae orci sed dolor rutrum auctor. Vestibulum facilisis, purus nec pulvinar iaculis, ligula mi congue nunc, vitae euismod ligula urna in dolor. Praesent congue erat at massa.
            </p>

          </v-card-text>
        </v-card>
      </v-tab-item>
      
      <v-tab-item>
        <v-card flat>
          <v-card-title class="text-h5">
           MMMM
          </v-card-title>
          <v-card-text>
            <p>
              Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem. Sed hendrerit. Maecenas malesuada. Vestibulum ullamcorper mauris at ligula. Proin faucibus arcu quis ante.
            </p>

          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
-->
  

    
    
  <!-- !TODO adden 2 rows, dann promo box, dann 2 rows -->
<!--
     <v-row>
          <template v-for="n in 2">
            <v-col
              :key="n"
              class="mt-2"
              cols="12"
            >
              <strong>Category {{ n }}</strong>
            </v-col>

            <v-col
              v-for="j in 6"
              :key="`${n}${j}`"
              cols="6"
              md="2"
            >
              <v-sheet height="150"></v-sheet>
            </v-col>
          </template>
        </v-row>
    
-->


         <v-card 
              class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
     
<v-sheet
dark
  color="black"

>
              <v-card-title class="noWordBreak"><v-icon left>mdi-account-group-outline</v-icon> Follow DIYFRAME</v-card-title>
</v-sheet>

            <v-card-actions class="black">
              <v-btn @click="openLink('https://twitter.com/diyframe_xyz')" color="blue" dark depressed  outlined>
                <v-icon left>mdi-twitter</v-icon>TWITTER
              </v-btn>

                            <v-btn @click="openLink('https://www.instagram.com/diyframe.xyz/')"  color="purple" dark depressed  outlined>
                <v-icon left>mdi-instagram</v-icon>INSTAGRAM
              </v-btn>
            </v-card-actions>
          </v-card>



<!--
ADDEN - random card abwechslung

<v-card>
            <v-card-title class="text-h5">
           #TezQuakeAid
            </v-card-title>

              
            
            <v-card-subtitle>Tezos community initiative to support those affected by the recent earthquake in Turkey and Syria.</v-card-subtitle>

       
            
            <v-card-actions>
              <v-btn  outlined >
                SUPPORT NOW
              </v-btn>
            </v-card-actions>
            

            
          </v-card>


-->









      <v-row dense>
        <v-col
        cols="12"
        >
         <!--  v-if="getActiveFrame.length === 0" -->
        
        <!--
           <v-card 
 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>
  <v-card-title class="noWordBreak"><v-icon left>mdi-wallet</v-icon>SETUP A TEZOS GHOSTNET WALLET</v-card-title>
</v-sheet>

                        <v-card-actions class="black" >
              <v-btn dark  @click="openLink('https://blog.diyframe.xyz/setup-a-tezos-ghostnet-wallet')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>
-->




















  <v-col
        cols="12"
        >
          <v-card 
              class="mx-auto rounded-lg mb-8"
  max-width="600"
    min-width="360"
    outlined
    flat
          >
     
<v-sheet
dark
  color="black"

>
              <v-card-title class="noWordBreak"><v-icon left>mdi-image-filter-none</v-icon> Create Your First Frame</v-card-title>
</v-sheet>

            <v-card-actions class="black">
              <v-btn @click="openLink('https://blog.diyframe.xyz/create-your-first-diyframe/')" dark depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

          <v-card 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>
  <v-card-title class="noWordBreak"><v-icon left>mdi-devices</v-icon>Setup A Device And Display For Diyframe</v-card-title>
 <!-- <v-card-subtitle>TUTORIAL</v-card-subtitle> -->
</v-sheet>

            <v-card-actions class="black" >
              <v-btn dark @click="openLink('https://blog.diyframe.xyz/setup-a-device-and-display-for-diyframe/')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

       
<!--
   <v-col
        cols="12"
        >
          <v-card 
             class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
          <v-sheet
          dark 
  color="black"
>
                   <v-card-title  class="noWordBreak"><v-icon left>mdi-image-filter-center-focus</v-icon> NEED HELP? CONTACT ANDRE FUCHS</v-card-title>
</v-sheet>

            <v-card-actions class="black">
              <v-btn @click="openLink('https://twitter.com/AndreFvchs')" dark depressed block outlined>
                <v-icon left>mdi-twitter</v-icon>CONTACT
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        -->
      </v-row>

    </v-container>
 
  </template>



<!-- #showframe 
 <template v-if="this.$route.path !=='/' && this.$route.path !== '/frames' && this.$route.path !=='/explore' && this.$route.path.indexOf('/explore/') !== -1 && this.$route.path !== '/about' && this.$route.path !== '/home' ">
-->


<!--
   <template v-else>
&& starts with tz || min 1 char?

= is showframe

 <template v-if="this.$route.path==='/add' || this.$route.path==='/add/'">
-->

<!-- #add -->

 <template v-if="this.$route.path==='/add' || this.$route.path.indexOf('/add/') !== -1">



      <v-card 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    min-width="360"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>

TOFO GO split /add/ [last index] ->load wie spotlight
{{this.$route.path
}}

     <v-card-title>TITLE</v-card-title>
          <v-card-subtitle class="pb-4">
 ARTIST
    </v-card-subtitle>

<v-divider class="white"></v-divider>

    <v-img
      class="white--text align-top"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
      contain
      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
    >
   
    </v-img>



    <v-card-text class="text--primary">
   DESCRIPTION
    </v-card-text>

    <v-card-actions>

      <!-- TODO ADD;  @click="addToFrame(item, item.id,item.frame,item.fa2_address)" :key="index+item.id+item.platform" -->
      <v-btn
      
        color="white"
        outlined
        block
      >
        ADD TO FRAME
      </v-btn>

<!--
      <v-btn
        color="orange"
        text
      >
        CANCEL
      </v-btn>
      -->
    </v-card-actions>
</v-sheet>
  </v-card>
</template>

<!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->

<!-- #about -->


  

   <template v-if="this.$route.path==='/about'">

    <v-container class="black" fluid height="100%">
      <v-row dense>
        <v-col
        cols="12"
        >

  <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
   
  >
    <v-sheet
dark
  color="black"

>
    <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
         DONATE
        </v-list-item-title>
        <v-list-item-subtitle class="white--text mb-4">Your support via tez or NFT donations are gladly accepted.</v-list-item-subtitle>
  
<v-tooltip v-model="showTooltip" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed outlined class="subtitle-2 mb-2 custom-transform-class text-none" color="white"
                      @click="copyToClipboard('tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc')"
                      @click.native="showTooltip =! showTooltip">
                      tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc
                      <v-icon right     v-bind="attrs"
              v-on="on">mdi-content-copy</v-icon>
   </v-btn>
                  </template>
                
                  <span>Copied <v-icon left>mdi-clipboard-check-multiple-outline</v-icon> I really appreciate your
                    support. Thank
                    you! <i>Andre</i></span>

                </v-tooltip>


                <v-btn depressed outlined class="subtitle-2 mb-2 custom-transform-class text-none"
                  @click="openLink('https://tzkt.io/tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc/operations/')"
                  color="white">
                  diyframe.tez on tzkt.io <v-icon right>mdi-arrow-top-right</v-icon>
                </v-btn>
      </v-list-item-content>
    
</v-list-item>
</v-sheet>
  </v-card>


 <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
  >
      <v-sheet
dark
  color="black"
 
>
   <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
          ABOUT
        </v-list-item-title>
<v-avatar
  size="100"
  color="black"
>
  <img src="@/assets/avatar.png" alt="">

</v-avatar>

    <v-card-text>
   <div>DIYFRAME is founded and developed by Andre Fuchs. <br/>
   Andre is a senior developer with a diploma in communication design and multimedia.</div>
    </v-card-text>

    <v-card-actions>
            <v-btn
        color="white"
        outlined
    @click="openLink('https://twitter.com/andrefvchs')"
      >
       <v-icon left>mdi-twitter</v-icon> Twitter
      </v-btn>
      <v-btn
        color="white"
        outlined
    @click="openLink('https://andrefuchs.xyz/')"
      >
      <v-icon left>mdi-smart-card</v-icon>  WEBSITE
      </v-btn>

    
<!--
         <v-btn
        color="white"
        outlined
    @click="openLink('https://www.fxhash.xyz/u/andre')"
      >
       <v-icon left>mdi-application-array-outline</v-icon> FXHASH
      </v-btn>
      -->
      <!-- objkt https://objkt.com/profile/andrefuchs/created -->
    </v-card-actions>
      </v-list-item-content>
    
</v-list-item>
</v-sheet>
  </v-card>

  <v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
  >
        <v-sheet
dark
  color="black"
 
>

 <v-list-item three-line>
      <v-list-item-content>
     
        <v-list-item-title class="text-h5 mb-1">
          CONTACT
        </v-list-item-title>

  <v-card-text>
   <div>Please send your feedback, feature requests and bug reports.</div>
    </v-card-text>

<v-card-actions>
<v-btn dark  class="text-lowercase" @click="openLink('mailto:andre@diyframe.xyz?subject=diyframe')" depressed  outlined>
             andre@diyframe.xyz
              </v-btn>

<v-btn dark  class="text-lowercase" @click="openLink('https://diyframe.xyz')" depressed  outlined>
             diyframe.xyz
              </v-btn>
<!--
              <v-btn dark  class="text-lowercase" @click="openLink('https://tzkt.io/tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc/operations/')" depressed  outlined>
             diyframe.tez
              </v-btn>
-->
</v-card-actions>



          </v-list-item-content>
    
</v-list-item>
    </v-sheet>
  </v-card>

<v-card
    class="mx-auto rounded-lg mb-4"
    max-width="600"
   outlined
   color="black"
    flat
  >
   <v-card-text class="grey--text">
  DIYFRAME by Andre Fuchs, 2023 | Version 0.1.0 (Mainnet Alpha)
    </v-card-text>
</v-card>

     </v-col>
              </v-row>
               
                 </v-container>
  </template>


<!-- #frames if else if machen später? -->
  <template v-if="this.$route.path==='/frames'"  >

    <v-container class="black" fluid height="100%">
      <v-row dense>
        <v-col
        cols="12"
        >
<!-- TODO container fehlt  === 0 or null or undefined -->
<!--
getActiveFrame {{ getActiveFrame.length }}

hier convertIDframe

arrayMain {{ arrayMain }}


// this.convertFrameIDS(this.getActiveFrame[i].frame,this.getActiveFrame[i].id) 

aber noch am laden:
-->

<!--  v-if="noFrame || getActiveFrame.length <= 0 || getActiveFrame.length === undefined " -->
  <v-card 
  v-if="userAddress === ''"
 
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>

  <v-card-title class="noWordBreak"><v-icon left>mdi-wallet</v-icon>Connect Your Tezos Wallet To Get Started</v-card-title>
</v-sheet>

            <v-card-actions class="black" >
              <v-btn  class="white" @click="connect" depressed block outlined>
                <v-icon left>mdi-login-variant</v-icon>Connect Wallet
              </v-btn>
            </v-card-actions>
            <!--
                        <v-card-actions class="black" >
              <v-btn dark  @click="openLink('https://blog.diyframe.xyz/setup-a-tezos-ghostnet-wallet')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
            -->
          </v-card>


  <v-card
  v-if="userAddress !== ''"
  class=" mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
  >
  <v-sheet
dark
  color="black"

>

    <v-card-title><v-icon left>mdi-information-outline</v-icon> Frames Are On-Chain</v-card-title>
   <v-card-subtitle>
   
    Newly created frames and added artworks may take a few minutes until they're showing up here.

     
    </v-card-subtitle>
</v-sheet>

   
  </v-card>



 <v-card 
  v-if="userAddress !== '' && getActiveFrame.length <= 0"
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>

  <v-card-title class="noWordBreak"><v-icon left>mdi-fullscreen</v-icon>You Don't Have Any Frames Yet</v-card-title>
<v-card-subtitle>Go to explore and add an NFT to a frame</v-card-subtitle>

</v-sheet>

<!-- @click="storeOnContractNewFrame('')"  -->
            <v-card-actions class="black" >
              <v-btn class="white"  @click="changeURL('EXPLORE')" to="/explore" depressed block outlined>
                <v-icon left>mdi-login-variant</v-icon>CREATE A FRAME
              </v-btn>
            </v-card-actions>
                        <v-card-actions class="black" >
              <v-btn dark  @click="openLink('https://blog.diyframe.xyz/create-your-first-diyframe/')" depressed block outlined>
                <v-icon left>mdi-newspaper-variant-multiple-outline</v-icon>TUTORIAL
              </v-btn>
            </v-card-actions>
          </v-card>




<!-- #test -->
<!--
 <v-card   
          v-for="(item, index) in arrID"
          :key="index">


 {{ index }} ||| {{ arrID[index].diyframe_frame_id  }} || {{ arrID[index].token_id  }} ||  {{ arrID[index].name  }}   
 </v-card>
-->

<!-- #cm  #current -->
<template v-if="getActiveFrame.length >= 0 && getActiveFrame.length !== undefined && arrayMain.length >= 0">

 <!-- {{ convertFrameIDS(getActiveFrame[0].frame,getActiveFrame[0].id) }} -->

  <v-card
  v-for="(item, index) in arrayMain"
    class=" mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
    :key="index"
   
  >
<v-sheet
dark
  color="black"

>

    <v-card-title>
  FRAME {{ arrayMain[index][0].diyframe_frame_id }}   
  
  <v-spacer></v-spacer>
  <v-menu
            bottom
            left
            dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title @click="copyToClipboardLink('https://to.diyframe.xyz/' + arrayMain[index][0].diyframe_frame_id)" color="white"><v-icon left>{{ item.icon }}</v-icon>
               <v-icon left>mdi-content-copy</v-icon> Copy Frame Short Link</v-list-item-title>
  </v-list-item>
              <v-list-item>
                <v-list-item-title @click="deleteFrameFromChain(arrayMain[index][0].diyframe_frame_id)" color="white"><v-icon left>{{ item.icon }}</v-icon>
                 <v-icon left>mdi-trash-can-outline</v-icon> Delete Frame</v-list-item-title>
  </v-list-item>

             

            </v-list>
          </v-menu>

</v-card-title>
</v-sheet>
<v-divider></v-divider>

<!--
{{ Object.entries(arrayMain[index]).length }}
-->

<template v-if="Object.entries(arrayMain[index]).length > 1">
<v-card
  v-for="(itemX, indexX) in arrayMain[index]"
    class="mx-auto rounded-0"
    max-width="600"    
    flat
    outlined
    :key="indexX"
   color="black"
   
      >
<v-sheet
dark
  color="black"

>

<v-list-item two-line >

 <v-list-item-avatar tile class="mr-2">
 <v-img :src="IPFStoHTTPobjkt(itemX)"  contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ itemX.name }}</span> 
 
 • <span v-if="itemX.artist_profile !== null && itemX.artist_profile!== undefined">{{getUsername(itemX.artist_profile.alias,itemX.artist_address) }}</span></v-list-item-title>

  <v-list-item-subtitle>
 <span class="text-capitalize"> {{ itemX.platform }}</span> • <span class="font-weight-bold">{{ itemX.token_id }} </span>
     </v-list-item-subtitle>


 </v-list-item-content>

    </v-list-item>

 <v-divider class=""></v-divider>
 
 </v-sheet>
</v-card>
</template>

<!-- TODO loading state rein -->
<template v-else>


<v-sheet
dark
  color="black"

>


<span class="white"    v-if="getLoading">LOADING...</span>



<v-list-item two-line>
  

 <v-list-item-avatar tile class="mr-2">
 <v-img :src="IPFStoHTTPbutiq(item[0])"  contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ item[0].name }}</span> 
 
 • <span v-if="item[0].artist_profile !== null && item[0].artist_profile!== undefined">{{getUsername(item[0].artist_profile.alias,item[0].artist_address) }}</span></v-list-item-title>

  <v-list-item-subtitle>
 <span class="text-capitalize"> {{ item[0].platform }}</span> • <span class="font-weight-bold">{{ item[0].token_id }}</span>
     </v-list-item-subtitle>
 </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    </v-sheet>
         </template>

  <v-card-actions class="black">
  

         <v-btn @click="openLink(openFrameURL(item[0].diyframe_frame_id))" outlined depressed  class="white"><v-icon left>mdi-play</v-icon>VIEW FRAME</v-btn>
         <v-spacer></v-spacer>
               
                      <!-- TEMP OFF  
         <v-btn  @click.native="dialogEdit = true, activeFrameID = item[0].diyframe_frame_id" outlined  depressed color="white"><v-icon left>mdi-playlist-edit</v-icon>EDIT</v-btn>
       -->
                  <v-btn  @click="shareViaWebShare(openFrameURL(item[0].diyframe_frame_id),'Check out my DIYFRAME')" outlined depressed color="white"><v-icon left>mdi-share-variant</v-icon> </v-btn>
                    
                     </v-card-actions>
  </v-card>
</template>

     </v-col>
              </v-row>
               
                 </v-container>
</template>
     
 <!--
  </v-card>
 INSPI
      <div v-if="tab === 'active'">
        <FrameItem
          v-for="frame in getActiveFrame"
          :key="frame.id"
          :id="frame.id"
          :time="frame.time"
          :done="frame.done"
          :frame="frame.frame"
        />
      </div>

     
      <div v-if="tab === 'done'">
        <FrameItem
          v-for="frame in getDoneFrame"
          :key="frame.id"
          :id="frame.id"
          :done="frame.done"
          :time="frame.time"
          :frame="frame.frame"
        />
      </div>
      -->
 
 <!-- #explore -->
            <template v-if="this.$route.path==='/explore' || this.$route.path.indexOf('/explore/') !== -1">
             
<!-- TODO container fehlt  -->    

<v-container class="black" fluid height="100%">

   <v-row>
                <v-col
                 cols="12"
                >


 <v-card 
  v-if="userAddress === ''"
 
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
          >
<v-sheet
dark
  color="black"

>

  <v-card-title class="noWordBreak"><v-icon left>mdi-wallet</v-icon>Connect Your Tezos Wallet To Get Started</v-card-title>
</v-sheet>

            <v-card-actions class="black" >
              <v-btn  class="white" @click="connect" depressed block outlined>
                <v-icon left>mdi-login-variant</v-icon>Connect Wallet
              </v-btn>
            </v-card-actions>
   
          </v-card>


  <v-card
   v-if="userAddress !== ''"
    class="mx-auto rounded-lg mb-4"
    max-width="600"
    outlined
    flat
    
     >
    <v-sheet
dark
  color="black"

>
        <v-card-title primary-title>
  SEARCH
</v-card-title>
                <!-- 
                SEARCH RESULTS FOR: {{  }}
-->
 <v-form>
              
           
                      <!--          prepend-inner-icon="mdi-magnify"  hint="Artist name, token name"   @submit.prevent="doFetch(searchQuery)" -->
                      <v-text-field
                   
                      @keydown.enter.prevent="doFetchSearch(searchQuery)" 
                        v-model="searchQuery"
                        outlined
                        clearable
                        placeholder="andrefuchs.tez or tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"
                        label="Search ꜩ or .tez address..."
                        
                        class="pr-2 pl-2"
                        color="grey darken-2"
                      hint="Enter a .tez address, wallet address, or try these:"
                     persistent-hint
                  
                      >

                      <!--
                        <template v-slot:prepend>
                          <v-tooltip
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            Search artist name, token ID, token name... 
                          </v-tooltip>
                        </template>
                      -->
                        <!--
                        <template v-slot:append>
                          <v-fade-transition leave-absolute>
                            <v-progress-circular
                              v-if="loading"
                              size="24"
                              color="info"
                              indeterminate
                            ></v-progress-circular>
                           
                      
                              <img
                              v-else
                              width="24"
                              height="24"
                              src="https://cdn.vuetifyjs.com/images/logos/v-alt.svg"
                              alt=""
                            >
                         
                          </v-fade-transition>

                          
                          <v-btn text class="success  pt-0 mt-0" @click="doFetchSearch(searchQuery)" >SEARCH</v-btn>
                      
                        </template>
 -->
                           <!--
                        <template v-slot:append-outer>
                          <v-menu
                            style="top: -12px"
                            offset-y
                          >
                       
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left>
                                  mdi-menu
                                </v-icon>
                                Options
                              </v-btn>
                            </template>
                       
                            <v-card>
                              <v-card-text class="pa-6">
                                <v-btn
                                  large
                                  text
                                  color="primary"
                                  @click="clickMe"
                                >
                                  <v-icon left>
                                    mdi-target
                                  </v-icon>Click me
                                </v-btn>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                           
                        </template>
                          -->
                      </v-text-field>

                      <!--     <v-btn small outlined color="success">tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v</v-btn>
                   <small>Search for Tezos address like tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v </small>
                       , token id, title, tag, tezos address, tezos domain...</small> -->
                 
              

              </v-form>
<!--
   <v-card-title class="subtitle-2 grey--text text--lighten-2">
     Collections.
</v-card-title>
-->
    <v-card-text>
   <v-btn class="mr-2 black--text" @click="doFetchSearch(getPkh), searchQuery=getPkh" small   color="white">YOUR COLLECTION</v-btn>

<!--
<v-btn class="mr-2" @click="doFetchSearch('tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v'), searchQuery='tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v'" small outlined color="grey">Andre Fuchs</v-btn>
-->

<v-btn class="mr-2" @click="doFetchSearch('tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc'),searchQuery='tz1Wd3rTuL1nZ62d1V9LtjYRtjJuD7vg6aSc'" small outlined color="grey">DIYFRAME</v-btn>
<!--

<v-btn class="mr-2" @click="doFetchSearch('tz1PoDdN2oyRyF6DA73zTWAWYhNL4UGr3Egj'),searchQuery='tz1PoDdN2oyRyF6DA73zTWAWYhNL4UGr3Egj'" small outlined color="grey">Ciphrd</v-btn>



<v-btn class="mr-2" @click="doFetchSearch('tz1NQLhEcgctcNUMmj46qRnWSrcs9iBREgJP'),searchQuery='tz1NQLhEcgctcNUMmj46qRnWSrcs9iBREgJP'" small outlined color="grey">LewisOsb</v-btn>

<v-btn class="mr-2" @click="doFetchSearch('tz1TxDL7npfYDSyvrZFC4deUPMySvsL6xARU'),searchQuery='tz1TxDL7npfYDSyvrZFC4deUPMySvsL6xARU'" small outlined color="grey">P1xelfool</v-btn>
-->

<!--
<v-btn class="mr-2" @click="doFetchSearch('tz1TxDL7npfYDSyvrZFC4deUPMySvsL6xARU'),searchQuery='tz1TxDL7npfYDSyvrZFC4deUPMySvsL6xARU'" small outlined color="grey">?</v-btn>
-->

<!-- TODO latest mint, featured artist -->

    </v-card-text>
     
</v-sheet>



 <v-list dark three-line color="black">

        

                    <v-list-item
     v-if="loadingCollection"
                        >

                       <v-list-item-avatar tile class="mr-2" >
 <v-img src="" lazy-src="@/assets/v-logo.png" contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content>
 <v-list-item-title><span class="font-weight-bold">Loading Collection...</span></v-list-item-title>

<!-- TIPPS HIER ANZEIGEN?! -->
  <v-list-item-subtitle>
<span class="font-weight-bold">Objkt, Teia, Fxhash, Hicetnunc, Versum</span>
   </v-list-item-subtitle>

  <v-list-item-subtitle>
 <span class="">Please wait...</span>
     </v-list-item-subtitle>
 </v-list-item-content>


                            <!-- muss anders für all search 
                                   <v-list-item-subtitle> {{ item.artifact_uri }}</v-list-item-subtitle>
                            <v-list-item-subtitle> {{ item.display_uri}}</v-list-item-subtitle>
                            
                          ERRRO
                            <v-list-item-subtitle > {{ item.artist_profil.alias }}</v-list-item-subtitle>
                       -->
                        
                      
                           </v-list-item>

                                        <v-progress-linear
 v-if="loadingCollection"
     indeterminate
      color="orange darken-4"
    ></v-progress-linear>

                    </v-list>




    <!-- 
                  <v-card
                   v-if="this.$route.path==='/explore'"
                   class="mx-auto mt-2 rounded-lg"
                   max-width="600"
                   outlined
                   flat
                   
                   >
              height="100h"   !getLoading && arrConvertedID.length >0 &&  <v-subheader>SEARCH RESULTS FOR andre</v-subheader>-->
      
                   
                    <v-list dark three-line color="black">

        

                    <v-list-item
      v-for="(item, index) in  tokenResult"
                          :key="index"
                                                   :class="[{selected: index % 2 != 0}, {even: index % 2 == 0}]"
                        >



                       <v-list-item-avatar tile class="mr-2" >
 <v-img :src="IPFStoHTTPbutiq(item.token)" lazy-src="@/assets/v-logo.png" contain width="50" height="50"></v-img>
 </v-list-item-avatar>

 <v-list-item-content>
 <v-list-item-title><span class="font-weight-bold">{{ item.token.name }}</span></v-list-item-title>

<!-- was das problem? -->
  <v-list-item-subtitle>
<span class="font-weight-bold" v-if="item.token.artist_profile !== null && item.token.artist_profile!== undefined">{{getUsername(item.token.artist_profile.alias,item.token.artist_address) }}</span>
   </v-list-item-subtitle>

  <v-list-item-subtitle>
 <span class=""> {{ item.token.platform }}</span> • <span class="font-weight-bold">{{ item.token.token_id }}</span>
     </v-list-item-subtitle>
 </v-list-item-content>


                            <!-- muss anders für all search 
                                   <v-list-item-subtitle> {{ item.artifact_uri }}</v-list-item-subtitle>
                            <v-list-item-subtitle> {{ item.display_uri}}</v-list-item-subtitle>
                            
                          ERRRO
                            <v-list-item-subtitle > {{ item.artist_profil.alias }}</v-list-item-subtitle>
                       -->
                        
                          <v-list-item-action>
                            <!--  @click.native="dialogAdd =! dialogAdd" #add -->
                                <v-btn
                                @click ="openDialogAddFrame(item.token.platform,item.token.token_id,item.token.fa2_address)"
                                  color="white"
                                  dark
                                  outlined
                                  depressed
                                                                 >
                                <v-icon color="" left>mdi-plus</v-icon> ADD
                                </v-btn>
                        
                          </v-list-item-action>
                           </v-list-item>
                                          
                    </v-list>
                  </v-card>

                  <!-- via dingens example?! 
                  LOAD MORE (200)
               
                   </v-card>    -->
                </v-col>
              </v-row>
               
                 </v-container>
              </template>


<!-- #dialogadd -->
<v-dialog
      v-model="dialogAdd"
       fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

 <v-card height="100h" flat tile color="black">

 <v-toolbar
            flat
            dark
            color="black"
          >


            <v-btn
              icon
              dark
              @click="dialogAdd = false"
            >
              <v-icon>mdi-close</v-icon> <!-- #current DISCARD changes -->
            </v-btn>

            <v-toolbar-title>ADD TO FRAME<v-progress-circular
              v-if="loading"
      class="ml-2"
      color="red"
      indeterminate
    ></v-progress-circular></v-toolbar-title>
              <!--
                   <v-spacer></v-spacer>
           
                        <v-btn
                        @click="handleFrameUpdate()"
                        color="white"
                        outlined
                          dark
                          class="mt-1"                      
                        >
                        <v-icon left >mdi-upload</v-icon>  PUBLISH
                        </v-btn>
                        -->
            <v-toolbar-items>
                    </v-toolbar-items>
                    </v-toolbar>

<!--
    <v-alert
      dense
     
      color="black"
      class="grey--text text--darken-2"
    >
      <v-icon small left color="grey darken-2">mdi-information-outline</v-icon> Click publish after editing to update your frame.
    </v-alert>
    -->

<!--
  <template>
            <v-form v-model="valid">
              <v-container class="mx-auto text-xs-center">
                <v-row>
                  <v-col
                    cols="12"
                  >
<v-btn @click="storeOnContractNewFrame(toSaveDID)" block color="white" outlined depressed dark  large><v-icon  left>mdi-plus</v-icon> CREATE NEW FRAME</v-btn>

                  </v-col>
          
                 
                </v-row>
              </v-container>
            </v-form>
          </template>
          -->

<v-card
 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
  
     color="black"
  >


<!--
<template v-if="Object.entries(filteredItems[index]).length > 1">
  -->

  <v-sheet
dark
  color="black"

>

 <v-btn  @click="storeOnContractNewFrame(toSaveDID)" block color="white" outlined depressed dark  large><v-icon  left>mdi-plus</v-icon> CREATE NEW FRAME</v-btn>

 </v-sheet>
</v-card>


 <v-card
 
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat
  

  >


<!--
<template v-if="Object.entries(filteredItems[index]).length > 1">
  -->

  <v-sheet
dark
  color="black"

>

<v-card
  
    class=" mx-auto rounded-lg"
    max-width="600"    
    flat
    outlined
   
  
 
      >

<!-- TODO:  multiple adden in   <v-list-item-group später wenn batch ready -->
        
    <v-list three-line color="black">
      <v-list-item-group
        v-model="selected"
        active-class="orange--text text--darken-4"
       
      >
        <template v-for="(item, index) in getActiveFrame">
          <v-list-item  @click="addToFrame(item, item.id,item.frame,item.fa2_address)" :key="index+item.id+item.platform">
            <template v-slot:default="{ active }">

                <v-list-item-action>
               <v-checkbox
                :input-value="active"
                color="orange darken-4"
              ></v-checkbox>
              </v-list-item-action>


              <v-list-item-content>
                <v-list-item-title>FRAME <span class="font-weight-bold">{{ item.id }}</span></v-list-item-title>

                <v-list-item-subtitle
                  class="text--grey"
                  
                >{{ item.frame }}</v-list-item-subtitle>

<!--
                <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                -->
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text>{{ formatDate(item.time) }}</v-list-item-action-text>
 </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>



   <!-- GET frame id -->
    <!--
 <v-list two-line dark>

      
  
        <v-list-item
      
 @click="addToFrame(item, item.id,item.frame,item.fa2_address)"
 @click.native="checkbox = true"
          :key="index+item.id+item.platform"
        >
        
       <v-list-item-avatar>

    <v-checkbox
      v-model="checkbox"     
    ></v-checkbox>

    </v-list-item-avatar>
    
          <v-list-item-content>
<v-list-item-title class=" text-h5  mb-1">
     FRAME <span class="font-weight-bold">{{ item.id }}</span> <v-spacer>({{ formatDate(item.time) }})</v-spacer>
     </v-list-item-title>
      
    <v-list-item-subtitle>{{ item.frame }} </v-list-item-subtitle>
  </v-list-item-content>
         
        </v-list-item>
         <v-divider :key="index"></v-divider>

    </v-list>
    -->

</v-card>
 </v-sheet>
</v-card>

  </v-card>

<!--
       <v-list two-line dark>
      <template v-for="(item, index) in getActiveFrame">
      

        <v-list-item
      
 @click="addToFrame(item, item.id,item.frame,item.fa2_address)"
 @click.native="checkbox = true"
          :key="index+item.id+item.platform"
        >
        
       <v-list-item-avatar>

    <v-checkbox
      v-model="checkbox"     
    ></v-checkbox>

    </v-list-item-avatar>
    
          <v-list-item-content>

<v-list-item-title class=" text-h5  mb-1">
     FRAME <span class="font-weight-bold">{{ item.id }}</span>
     </v-list-item-title>
      

    <v-list-item-subtitle>{{ item.frame }} ({{ formatDate(item.time) }})</v-list-item-subtitle>


          </v-list-item-content>
         
        </v-list-item>
         <v-divider :key="index"></v-divider>
      </template>
    </v-list>
    -->
                  
          
          </v-dialog>
      
     




<!-- #dialogedit -->
  <v-dialog
      v-model="dialogEdit"
       fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >

 <v-card height="100h" flat tile color="black">
 <v-toolbar
            flat
            dark
            color="black"
          >
            <v-btn
              icon
              dark
              @click="dialogEdit = false"
            >
              <v-icon>mdi-close</v-icon> <!-- #current DISCARD changes -->
            </v-btn>

            <v-toolbar-title>EDIT FRAME {{ this.activeFrameID }}<v-progress-circular
              v-if="loading"
      class="ml-2"
      color="red"
      indeterminate
    ></v-progress-circular></v-toolbar-title>
              <v-spacer></v-spacer>
                        <v-btn
                        @click="handleFrameUpdate()"
                        color="white"
                        outlined
                          dark
                          class="mt-1"                      
                        >
                        <v-icon left >mdi-upload</v-icon>  PUBLISH
                        </v-btn>
            <v-toolbar-items>
                    </v-toolbar-items>
                    </v-toolbar>

    <v-alert
      dense
     
      color="black"
      class="grey--text text--darken-2"
    >
      <v-icon small left color="grey darken-2">mdi-information-outline</v-icon> Click publish after editing to update your frame.
    </v-alert>

 <v-card
  v-for="(item, index) in filteredItems"
    class="mx-auto rounded-lg mb-8"
    max-width="600"
    outlined
    flat 
    :key="index"
     color="black"
  >


  <v-sheet
dark
  color="black"

>

<v-card
  v-for="(itemX, indexX) in filteredItems[index]"
    class=" mx-auto rounded-lg"
    max-width="600"    
    flat
    outlined
    :key="indexX"
   color="black"
 
      >


<v-list-item three-line >
<!--
<v-list-item-avatar>
          
          {{ indexX }}
      
        </v-list-item-avatar>
-->
 <v-list-item-avatar tile size="75" class="mr-2">
 <v-img :src="IPFStoHTTPbutiq(itemX)"  contain width="75" height="75"></v-img>
 </v-list-item-avatar>

 <v-list-item-content >
 <v-list-item-title><span class="font-weight-bold">{{ itemX.name }}</span> </v-list-item-title>

  <v-list-item-title>
<span v-if="itemX.artist_profile !== null && itemX.artist_profile!== undefined">{{getUsername(itemX.artist_profile.alias,itemX.artist_address) }}</span>
     </v-list-item-title>

       <v-list-item-title>
 <span class="text-capitalize"> {{ itemX.platform }}</span> • <span class="font-weight-bold">{{ itemX.token_id }}</span>
     </v-list-item-title>
 </v-list-item-content>



 <v-list-item-action>
  <!-- TO ADD -->
                <v-list-item-action-text></v-list-item-action-text>
<!-- 
      <v-btn  @click="deleteFrameEntryID(itemX.token_id)" class="mt-4" outlined depressed color="white"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
      
      SHARE REIN! native + andere
      -->


        
              </v-list-item-action>
                  <v-btn v-show="true" @click="deleteFrameEntryID(itemX.token_id)" class="mt-4 mr-2" outlined depressed color="white"><v-icon>mdi-trash-can-outline</v-icon></v-btn>


          
            <v-menu
            bottom
            left
            dark

          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="mt-4" 
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title @click="openLink('https://objkt.com/asset/'+itemX.fa2_address+'/'+itemX.token_id)" color="white"><v-icon left>{{ item.icon }}</v-icon>
               <v-icon left>mdi-cart</v-icon> Collect on Objkt.com</v-list-item-title>
  </v-list-item>
              <v-list-item>
                <v-list-item-title @click="openIPFSLINK(itemX.artifact_uri)" color="white"><v-icon left>{{ item.icon }}</v-icon>
                 <v-icon left>mdi-launch</v-icon> View on IPFS</v-list-item-title>
  </v-list-item>

             

            </v-list>
          </v-menu>

    </v-list-item>

 <v-divider class=""></v-divider>
 

</v-card>
 </v-sheet>
<!--
</template>
-->
</v-card>
<!-- ADDEN
                <v-icon
             @click="deleteFrameEntryID(this.arrID, convertSingleDID(item[0].token_id,'FXHASH'))"
                 outlined
                  color="grey lighten-1"
                >
                mdi-trash-can-outline
                </v-icon>
-->

         </v-card>
    </v-dialog>


<!-- in main? -->
  <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
    >
      {{ textSnackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange darken-4"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>

        <!-- TODO if frame created = VIEW -->
      </template>
    </v-snackbar>
  </div>

<!-- 
 v-show="!getDevice()"
 class="hidden-sm-and-up"
-->

 </v-main>


<v-bottom-navigation
:value="value"
class="black hidden-sm-and-up orange--text"
       grow
               fixed
        flat
         background-color="black lighten-4"
         dark
          app
          color="white"
    >
          <v-btn :ripple="false" @click="changeURL('HOME')"  to="/">
<span>HOME</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('FRAMES | ' + getActiveFrame.length)" to="/frames">
        <span>FRAMES</span>
        <v-icon>mdi-image-filter-none</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('EXPLORE')" to="/explore">
        <span>EXPLORE</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn :ripple="false" @click="changeURL('ABOUT')"  to="/about">
        <span>ABOUT</span>
        <v-icon>mdi-image-filter-center-focus</v-icon>
<!--
                <v-img
          src="@/assets/favicon.svg"

          height="20"
          width="20"
          contain
       
        >
        </v-img>
        -->

      </v-btn>
    </v-bottom-navigation>

<!-- /APP -->
</template>
  </v-app>
</template>

<script>
// #import #b
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import { formatPkhString } from "./utils";
// import { useRouter } from 'vue-router'
import { format } from 'date-fns'

// import { queryAccessControl } from '@siwt/acq'
// import { useRoute } from 'vue2-helpers/vue-router';

// if ('serviceWorker' in navigator) {



if('serviceWorker' in navigator) {
    // Register the service worker
    navigator.serviceWorker.register('/sw.js', {
        scope: '/'
    });
}

// -------------------------------
let wakeLock = null

if ('wakeLock' in navigator) {
  console.log('❓ Wake Lock API Supported 🎉')
   // this.wakeLockSupport = true
// wakelock = await navigator.wakeLock.request('screen')
}

  
// TO ADD für working wakelock:
/*
document.addEventListener('visibilitychange', async () => {  
    if (wadocument.addEventListener('visibilitychange', async () => {  
    if (wakelock !== null && document.visibilityState === 'visible') {    
        wakelock = await navigator.wakeLock.request('screen')  
    }
})
*/

// if(this.$route.path.indexOf('/frame/tz') !== -1) {

/*
const requestWakeLock = async () => {
  try {
    wakeLock = await navigator.wakeLock.request()
    wakeLock.addEventListener('release', () => {
      console.log('👁️ Screen Wake Lock released:', wakeLock.released)
    })
    console.log('👁️ Screen Wake Lock released:', wakeLock.released)
  } catch (err) {
    console.error(`${err.name}, ${err.message}`)
  }
}

await requestWakeLock();

const handleVisibilityChange = async () => {
  if (wakeLock !== null && document.visibilityState === 'visible') {
    await requestWakeLock()
  }
}

document.addEventListener('visibilitychange', handleVisibilityChange)
*/

//}

// Request a screen wake lock…
// await requestWakeLock();
// …and release it again after 5s.
// window.setTimeout(() => {
// wakeLock.release();
// wakeLock = null;
// }, 5000);

// -------------------------------

// const route = useRoute();
// console.log(route); $account: String = ""
// #q

const queryEntryNFTholders = `query GetToken($fa2Address:'KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr', $tokenId: '4048') {
    tokens_by_pk(fa2_address: $fa2Address, token_id: $tokenId) {
        name
        description
        artist_address
        price
        offers(where: {status: {_eq: "active"}}, order_by: {price: desc}) {
            type
            contract_address
            price
            buyer_address
        }
        listings(where: {status: {_eq: "active"}}, order_by: {price: asc}) {
            type
            contract_address
            amount_left
            price
            seller_address
        }
        holdings(where: {amount: {_gt: "0"}}) {
            holder_address
            amount
        }
    }
}
`;

const queryTZprofile = `query GetTzProfileByAccount($valAccount: String = "") {
  tzprofiles(where: {account:{_eq: $valAccount}}) {
   account
   alias
  }
}
`;

const OFFqueryTZprofile = `query OFFGetTzProfileByAccount($valAccount: String = "") {
  tzprofiles_by_pk(account: $valAccount) {
   account
   alias
  }
}
`;

// TEST
const querySingleTokenIDobjkt = `query SingleTokenIDobjkt($valID: String = "",$valPlatform: String = "") {
  tokens(where: {fa2_address: {_eq: $valID}, token_id: {_eq: $valPlatform }}) {
   name
    artist_profile {
        alias
      }
      artist_address
       objkt_artist_collection_id
      
       fa2_address
      token_id
      platform
       artifact_uri
    display_uri
    thumbnail_uri
    mime_type
  }
  }
`;




    const querySingleTokenID = `
 query SingleTokenID($valID: String = "",$valPlatform: String = "") {
  tokens(where: {token_id: {_eq: $valID}, platform: {_eq: $valPlatform }}) {
    name
    artist_profile {
        alias
      }
      artist_address
       objkt_artist_collection_id
       
       fa2_address
      token_id
      platform
       artifact_uri
    display_uri
    thumbnail_uri
    mime_type
  }
}
`;


// holdings(where: {holder_address: {_eq: $address}, platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}) {
// für search /explore all items by collector

// store platform + token id ::: f v o t + [id] f_15 split 15 ->dann resolve in frame ->load data ->display data

// 
    // holdings(where: {holder_address: {_eq: $address}, amount: {_gt: "0"}} ,order_by: {last_received_at: desc}, token: {platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}}) {
//  {_in:"FXHASH", "OBJKT", "HEN", "VERSUM"}, 


const queryAllTokensByCollector = `query TokensByCollector($address: String!) {
   
    holdings(where: {holder_address: {_eq: $address}, amount: {_gt: "0"}, token: {platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}}, order_by: {last_received_at: desc}) {
     token {
      platform
      fa2_address
       objkt_artist_collection_id
      token_id
         name
    description
    updated_at
    thumbnail_uri
    mime_type
        artist_address
    artist_profile {
        alias
      }
    }
  
 }
}`;

// store platform + token id ::: f v o t + [id] f_15 split 15 ->dann resolve in frame ->load data ->display data
const queryAllTokensByArtist = `query TokensByArtist($address: String!) {
  tokens(where: {_or: [{royalty_receivers: {receiver_address: {_eq: $address}}}, {artist_address: {_eq: $address}}], platform: {_in: ["FXHASH", "OBJKT", "HEN", "VERSUM"]}}, limit: 100) {
    platform
    token_id
    fa2_address
    objkt_artist_collection_id
    artist_address
    artist_profile {
        alias
      }
    name
    description
    updated_at
   
    thumbnail_uri
    mime_type
    royalty_receivers {
      receiver_address
    }
  }
}`;

/* 
 display_uri
    artifact_uri
    artifact_metadata
    */


const ORGquery = `
  query OBJKTBuyers($address: String = "") {
    hic_et_nunc_trade(where: {seller: {address: {_eq: $address}}, token: {creator: {address: {_eq: $address}}}}, distinct_on: buyer_id) {
      buyer {
        address
        purchases_aggregate(where: {seller: {address: {_eq: $address}}}) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;



/*
 { icon: 'mdi-account-box-outline', text: 'Profile' },
        { icon: 'mdi-cog-outline', text: 'Settings' },
        { icon: 'mdi-chat-outline', text: 'Chat' }

        const VBtn = Vue.component('VBtn')
VBtn.options.props.ripple.default = false



 <v-list >
 <v-list-item @click="changeURL('HOME')" class="grey--text hidden-sm-and-down" link to="/">
          <v-list-item-icon>
            <v-icon color="grey darken-2">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeURL('FRAMES | ' + getActiveFrame.length)" class="grey--text hidden-sm-and-down" link to="/frames">
          <v-list-item-icon>

           <v-icon color="grey darken-2">mdi-image-filter-none</v-icon>
          </v-list-item-icon>
          <v-list-item-title>FRAMES</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeURL('EXPLORE')" class="grey--text hidden-sm-and-down" link to="/explore">
          <v-list-item-icon>
           <v-icon color="grey darken-2">mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-title>EXPLORE</v-list-item-title>
        </v-list-item>
            <v-list-item @click="changeURL('ABOUT')" class="grey--text hidden-sm-and-down" link to="/about">
          <v-list-item-icon>
           <v-icon color="grey darken-2">mdi-image-filter-center-focus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>ABOUT</v-list-item-title>
        </v-list-item>
</v-list>


        { title: 'Click Me', icon: 'mdi-magnify' },
        { title: 'Click Me 2', icon: 'mdi-magnify' },

        */
// #data #r F1416921 F1541588 KT1NReyntEwNeyJEu8L39qCAMkBVWERnVptu-4

// itemsInterval in seconds
export default {
  
   data() {
    return {
      dataCurrentFrameToken: null,
       itemsInterval: [
         { title: '10 seconds', interval: 10 },
        { title: '30 seconds', interval: 30 },
        { title: '5 minutes', interval: 300 },
      ],
       itemsFrameMenu: [
        { title: 'Copy Short Link', icon: 'mdi-content-copy' },
        { title: 'Delete Frame', icon: 'mdi-trash-can-outline' },

      ],
      volumeOn: false,
       checkbox: false,
      OFFnoFrame: false,
      arrayFeatured: [],
      itemsCarousel: [
          {
            id: 'H801055',

          },
           {
            id: 'O5-KT1WbSDyQndRBWFn7MrrgcaDZjCQ6X6WWdJB',

          },
          
          {
            id: 'O8-KT1XtAJLiVAQv95Z7929TpbgzZ9WhfSFAJcq',
 
          },
          {
            id: 'O20-KT1TKgN2UvycRtbVbYXX7Nxa3UFqNt25wmgr',

          },
          {
           id: 'H811383'
          },

         
        ],
       tabs: null,
       marketplaces: 0,
        text: 'Lorem ipsum do',
       itemsTabs: [
       'objkt', 'fxhash', 'versum', 'teia',
      ],
      colors: [
        'green',
        'secondary',
        'yellow darken-4',
        'red lighten-2',
        'orange darken-1',
      ],
      cycle: false,
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      cycleInterval: 30000,

      address: '',
      arrIPFS: ["https://ipfs.io/ipfs/","https://cloudflare-ipfs.com/ipfs/","https://gateway.pinata.cloud/ipfs/","https://gateway.ipfs.io/ipfs/"],
 arrFrameIDSConverted: [],
       arrFrameIDS: [], 
       cycleActiveFrame: 0,
      cycleCollectionTimer: null, 
    loadingCollection: false,

      wakeLockRequested: null,
        drawerOFF: null,
 drawerRight: false,

      links: [
          { title: 'Home', icon: 'mdi-home', link: '/' },
          { title: 'Frames', icon: 'mdi-image-filter-none', link: '/frames' },
          { title: 'Explore', icon: 'mdi-magnify', link: '/explore' },
           { title: 'About', icon: 'mdi-image-filter-center-focus', link: '/about' },
            
           
        ],

      mimeType: "",
      loadingInfo: false,
      mouseMoving: false,
      timer: null,
      loadedEntries: false,
            walletAvatar: "",
      walletAddress: "",
      urlFrameUserWallet: "",
      urlFrameID: "",
      subMainCounter: 0,
      counter: 0,
      arrayMain: [],
      objectMain: {},
      arrSub: [],
      arrFrame: [],
      arrIDsingle: [],
      target: { a: 1, b: 2 },
      toSaveDID: "",
      arrID: [],
      arrXX: [],
      frameArrX: [],
      arrFrame: [],
      arrTokenID: [],
      snackbar: false,
      textSnackbar: "",
      userAddress: "",
      messages: null,
            menuUser: [
       
      ],
      toolbarTitle: "HOME",
      showTooltip: false,
      showTooltipDonations: false,
       value: 0,
        active: true,
       selected: [],
      new_frame: "",
          toggling: false,
      deleting: false,
       frame: "",
      arrConvertedID: [],
      activeFrameID: "",
      tab: "active",
      dialogEdit: false,
              dialogAdd: false,
     explorePage: true,
      toSaveTokenID: '',
      toSavePlatform: '',
 valid: false,
 parisArr: [],
      firstname: '',
      lastname: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
          itemsDialogPlaylist: [
        'Exhibition Paris / Lounge',
        'Private / Living Room / TV',
        'NFT NYC / Room 1 / Display 1',
        'NFT NYC / Room 1 / Display 2',
        'Meetup Paris / Display 1',
      ],
      modeladdToFrame: [''],
      searchQuery:"",
      searchQueryDev:"tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v",
      tokenResult: [],
      itemsD: [
        { title: 'Andre Fuchs' },
        { title: 'LewisOsb' },
        { title: 'Pixelart' },
        { title: 'FXHASH' },
      ],
      message: '',
      loading: false,
      cards: ['Today', 'Yesterday'],
      drawer: false,
      first: '',
      last: 'Doe',
      itemsRow: [
      
        { title: '10' },
        { title: '25' },
        { title: 'ALL' }
      ],
      selection: [1],
      page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      selectedUsers: [],
      editFrame: '8',
    }},
  name: "App",
  components: {
   // Head
  },
  computed: {
    ...mapGetters(["getActiveFrame", "getDoneFrame", "getLoading","getConnected","getPkh"]),

    filteredItems() {
                    return this.arrayMain.filter(item => {
                        return item[0].diyframe_frame_id.indexOf(this.activeFrameID) > -1
                    })
    },

    OFFfilteredItems() {
                    return this.arrayMain.filter(item => {
                        // console.log(item.type.toLowerCase().indexOf(this.search.toLowerCase()))
                        console.log("item[0].diyframe_frame_id ",item[0].diyframe_frame_id);
                       return item[0].diyframe_frame_id.toLowerCase().indexOf(this.editFrame.toLowerCase()) > -1
                       // return item.diyframe_frame_id.indexOf(this.editFrame.toLowerCase()) > -1
                    })
                },
  },
  // #ted
    mounted() {
console.log("✅ mounted");

console.log("_____________");      
console.log("DIYFRAME by Andre Fuchs");
console.log("https://andrefuchs.xyz");
console.log("🦊️");
console.log("_____________");

// NO INSTALL BUTTON
   window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
    })

var isChromium = !!window.chrome;
console.log("❓ Chromium Browser", isChromium)
// console.log("❓ wakeLockSupport", wakeLockSupport)

// ENDE: update URL clean 
// 
document.title = "DIYFRAME by Andre Fuchs" 

this.doFetchTZprofile("tz1NQLhEcgctcNUMmj46qRnWSrcs9iBREgJP")

// console.log("✨ process.env", process.env.NODE_ENV)

// 1. CREATE AN ARRAY FOR EACH MEMO, console.table(arrTokenID); USB: 

// IDEE: nennen 71 72 73 frameID + FrameID innen
// IST ARRAY!

  this.$nextTick(function() {
       console.log("✅ mounted / nextTick");
//  === '/frame/tz1i4vowP9mHi83KTJikHwB433id3CXGasqU/16'

// PORTAL SCREEN
/* TEMP OFF
if(process.env.NODE_ENV === "development") {
  this.requestWakeLock()
}
*/




if(this.$route.path.indexOf('/frame/tz') !== -1) {
this.getFrameURL()
this.mouseMove()

// 
console.log("____wakeLock:", wakeLock);
// 
this.requestWakeLock()


if(this.$route.query.interval) {
this.cycleInterval = this.$route.query.interval*1000
}
// CHECK MEMO LENGTH

// if interval= intervalCycle = param
  this.cycleCollectionTimer = setInterval(() => {
  
    this.nextPieceInFrame('next')
  }, this.cycleInterval)

} else {

// console.log("this.$route.path.indexOf('/explore/",this.$route);


  // if(this.$route.path.indexOf('/explore/tz') !== -1) {


// ADD via API
   if(this.$route.path.indexOf('/add') !== -1) {
// ADD TO FRAME VIA API


   }


      if(this.$route.path.indexOf('/explore') !== -1) {
      // + set autodocus autofocus

// console.log(this.$route.params.id);
// get :id part of explore wie?
// const checkAddress = this.$route.path
if(this.$route.params.id !== undefined) {
  this.searchQuery = this.$route.params.id
}
     // this.searchQuery = 'andrefuchs.tez'
   // this.doFetchSearch('tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v')

 // console.log("explore tz");
   }

/*
TODO ENDE
AUS HICDEX
sold?address=tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v

 if (this.$route.query.address) {
      this.address = this.$route.query.address;
      this.searchQuery = this.address;
    }
    doFetchSearch(searchQuery)

CAN BE: /explore/tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v
CAN BE: /explore/andrefuchs.tez
CAN BE: /explore/andrefuchs + assume .tez add automatically
CAN BE: invalid explore/sadad626
wie abfragen was hinter query?

   if(this.$route.path.indexOf('/explore/tz') !== -1) {
    this.doFetchSearch('tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v')
  console.log("explore tz");
   }
   */
  // RICHTIG oder kann weg? muss rein erstmal 
    this.userAddress = this.getPkh

    // 
 this.checkWalletConnection();

// wait until loaded? loading === false !loading
    if(this.getActiveFrame.length > 0) {
     console.log("🥳 active frames");
// this.noFrame = false
// FÜR OEF AUFRUF aka direct url /frames aufruf
  for (var i = 0; i < this.getActiveFrame.length; i++) {
//    console.log("_this.getActiveFrame.length: ",this.getActiveFrame.length);
this.convertFrameIDS(this.getActiveFrame[i].frame,this.getActiveFrame[i].id) 
  } 
 
     //
}else {
    console.log("😱 no active frames");
   //  this.noFrame = true
  }
// /ELSE kein /frame/
}
    })


// TODO ENDE: /explore/tz address auto fetch
/*
if(this.$route.path.indexOf('/explore/tz') !== -1) {
  this.doFetchSearch('tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v')
}
*/

// MOBILE DESKTOP INITIAL SETUP console.log("$vuetify.breakpoint",this.$vuetify.breakpoint.lg);
if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
//
    } else {
      this.drawerRight = true
this.drawer = true
    }
/*
for (var i = 0; i < this.getActiveFrame.length; i++) {
this.arrTokenID[i].push("MEMO" + i,"NEW");
console.log("______arrTokenID", this.arrTokenID);
}
*/


// IF HOME ROOT

  if(this.$route.path.indexOf('/') !== -1) {
for (var i = 0; i < this.itemsCarousel.length; i++) {
this.doFetchSingleTokenFrameIDFeatured(this.itemsCarousel[i].id)
}
  }


// DELETE INVALID FRAME
// this.deleteFrameFromChain("3")


 //   for (var i = 0; i < this.getActiveFrame.length; i++) {
   // ERST WENN GELADEN
  //  console.log("this.getActiveFrame ",this.getActiveFrame[i]);
  //  this.convertMultipleDID(this.getActiveFrame[i].frame,this.getActiveFrame[i].id)
 //   }
  },
  watch: {
     searchQuery(newVal) {
       this.getAddress(newVal).then((address) => {
         if (address.length === 36 && this.address !== address) {
       this.doFetchSearch(address)  
        // console.log("valid address____fetch data"); // this.$router.push({ name: 'creator-gallery', query: { address } });
         }
         this.address = address;
       });
    },
    getActiveFrame(newValue) {

console.log("getActiveFrame ->newValue");

if(this.$route.path.indexOf('/frame/tz') !== -1) {
this.convertIDframe(this.getActiveFrame[0].frame)
} else {
this.getActiveFrameMethod()
}
//
    },
    getConnected(newValue) {
    console.log("🤝 getConnected()");

      if (newValue && this.getPkh !== undefined) {
    console.log("🤝 getConnected() / newValue");
// if(this.$route.path === '/frame/tz1i4vowP9mHi83KTJikHwB433id3CXGasqU/16') {

if(this.$route.path.indexOf('/frame/tz') !== -1) {

 this.userAddress = this.getPkh
 this.getFrameListFrame(this.urlFrameID);
} else {
      this.userAddress = this.getPkh
        this.getFrameList();
        //
 
 /*
 if(this.getActiveFrame >=0) {
  for (var i = 0; i < this.getActiveFrame.length; i++) {
    console.log("_this.getActiveFrame.length: ",this.getActiveFrame.length);
this.convertFrameIDS(this.getActiveFrame[i].frame,this.getActiveFrame[i].id) 
  } 
}
        */
}
//
      }
    },
  },
  created() {
    console.log("✅ created");
    // this.checkWalletConnection();
  },
  // #m
  methods: {
    ...mapActions(["connectionToContract","getFrameListFrame","checkWalletConnection", "getFrameList","addFrame", "toggleFrame","deleteFrame", "toggleFrameStatus","updateFrame","connectWallet", "disconnectWallet"]),
 
  async  doFetchEntryNFTholders() {
 // const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist");
  const { errors, data } = await this.fetchGraphQL(queryEntryNFTholders, "GetToken", {"f2aAddress":'KT1EfsNuqwLAWDd3o4pvfUx1CAh5GMdTrRvr',"tokenId":'4045'});

  if (errors) {
    console.error(errors);
  }
  const result = data
  console.log("xxxxxx ", { result })

  },
  async OFFdoFetchEntryNFT() {
      // console.log('doFetchEntryNFT')
      const { errors, data } = await this.fetchGraphQLEntryNFT(
        queryCheckEntryNFT,
        'Objkt',
        {
          // TODO id anpassen 702883 = official
          id: '702883',
        }
      )

      if (errors) {
        console.error(errors)
      }
      const result = data.hic_et_nunc_token_by_pk

      // TODO D MACHEN
      /*
      if (owner.id.includes(this.wallet)) {
        this.collectorFXHASH = true
        this.tabs = 2
      }
      */

      for (let i = 0, len = result.token_holders.length; i < len; i += 1) {
        if (
          result.token_holders[i].holder.address.includes(this.walletAddress)
        ) {
          // this.collectorFXHASH = true
          // this.tabs = 2
          this.validWalletAddress = true
          // OFF WEGEN NEUER ABFRAGE this.setValidWalletAddress()
          // LATER TEMP OFF
          // console.log('FOUND ENTRY NFT IT DO! LOGIN! ->')

          // WIEDER REIN VLLT:
          return
        }
        // LATER TEMP OFF
        this.validWalletAddress = false
        // tabs to not found ->waitlist or buy
        // flow aus claim invite rein
        // OFF WEGEN NEUER ABFRAGE this.tabs = 4
        // console.log('NOT FOUND ->SHOW INFO')

        //
      }

      // KEINE BETA? CHECK AUF LIFETIME LICENSE NFT :
      this.doFetchLifetimeLicenseNFT()
      // // console.log(result.token_holders.length)
      // // console.log('result: ', result.token_holders[0].holder.address)
      // // console.log({ result })
      // return result
    },
 changeMetaData(valTitle, valArtist) {
document.title = valTitle + " by " + valArtist + " on DIYFRAME"
 },
ipfsResolver(val){
  let randomIPFS = this.arrIPFS[Math.floor(Math.random()*this.arrIPFS)]
  console.log("randomIPFS ",randomIPFS);
return val.split('ipfs://').join(randomIPFS)
},
  pickTokenDisplayUri(token) {
  if (
    token.platform === 'FXHASH' &&
    token.thumbnail_uri &&
    token.thumbnail_uri === 'ipfs://QmbvEAn7FLMeYBDroYwBP8qWc3d3VVWbk19tTB83LCMB5S' &&
    token.fx_collection_display_uri
  ) {
    return {
      uri: token.fx_collection_display_uri,
    };
  }

  if (!token.display_uri && token.thumbnail_uri === 'ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc') {
    // HEN, early mints
    return {
      uri: token.artifact_uri,
      is_video: token.mime_type === 'video/mp4',
    };
  }

  if (
    token.platform === 'VERSUM' &&
    (token.mime_type === 'image/gif' ||
      token.mime_type === 'image/jpeg' ||
      token.mime_type === 'image/png' ||
      token.mime_type === 'image/jpg')
  ) {
    return {
      uri: token.artifact_uri,
    };
  }

// && token.formats !== undefined wieder raus?! 
  if (token.platform === 'VERSUM' && parseInt(token.token_id, 10) <= 27951 && token.mime_type === 'video/mp4' && token.formats !== undefined) {
    // the video was used in the display_uri in the early versum days. pick first jpg in formats instead.
    try {
      const jpgFormat = token.formats.find((format) => format.mime_type === 'image/jpeg');

      if (jpgFormat) {
        return {
          uri: jpgFormat.uri,
        };
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  }

  return {
    uri: token.display_uri || token.thumbnail_uri,
  };
},
shareViaWebShare(valURL,valText) {
     //   TODO später html2canvas preview pic generieren und via file mitshare
     // https://github.com/kissu/so-share-image-bounty

     // TODO: CHECK OF navigrator is supported
     // files: "@/assets/gallery.png"
     // GEHT NICHT
     const fileImage = "@/assets/gallery.png"



if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {

if (navigator.canShare && navigator.canShare({ files: fileImage })) {
  navigator.share({
    files: fileImage,
    url: valURL,
    text: valText
  })
  .then(() => console.log('Share was successful.'))
  .catch((error) => console.log('Sharing failed', error));
} else {
  console.log(`Your system doesn't support sharing files.`);
  this.openLink("https://www.addtoany.com/share#url="+valURL)

  // https://www.addtoany.com/add_to/twitter?linkurl=https%3A%2F%2Fdiyframe.xyz%2F&type=page&linkname=NAME&linknote=NOTE
}

/*
    navigator.share({
    
url: valURL,
    text: valText

  })
*/


} else {
     //    if("\n\n"+this.shareTags)
      //   hashtags = "\n\n"+this.shareTags
      // }     files: this.filesAttached,
      // TODO &text= adden
this.openLink("https://www.addtoany.com/share#url="+valURL)

  }



},
 isTezosDomain(address) {
  return /\.tez$/.test(address);
},

async resolveTezosDomain(domain) {
  try {
    const result = await fetch('https://api.tezos.domains/graphql', {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify({
        query: 'query resolveDomain($domain: String!) {\n  domain(name: $domain) {\n    address\n  }\n}\n',
        variables: { domain },
        operationName: 'resolveDomain',
      }),
    });

    const response = await result.json();
    return response?.data?.domain?.address || '';
  } catch (err) {
    return '';
  }
},

async getAddress(addressOrDomain) {
  if (this.isTezosDomain(addressOrDomain)) {
    const address = await this.resolveTezosDomain(addressOrDomain);
    if (address) {
      return address;
    }
  }

  return addressOrDomain;
},

 queueNextImage() {
  if(this.loadingImage !== null) {
    this.loadedImages.push(this.loadingImage)
  }
  this.loadingImage = this.imagesToLoad.shift()
},
 nextPieceInFrame(valNextPrev) {

this.mouseMove()

// TODO?! if loadedEntries > 0
if(this.arrFrameIDS !== null && this.arrFrameIDS.length > 0) {

// console.log("nextPieceInFrame / arrFrameIDS: ",this.arrFrameIDS.length);

 // this.filePath = this.convertFrameIDS(this.getActiveFrame[this.cycleActiveFrame].frame,this.getActiveFrame[this.cycleActiveFrame].id) 
  // convertIDframe
  
   // this.filePath = this.fetchSingleTokenFrameID(this.arrFrameIDS[this.cycleActiveFrame])

   if(valNextPrev === "next") {
this.cycleActiveFrame++
} else {
  this.cycleActiveFrame--
}

console.log("___this.cycleActiveFrame: ",this.cycleActiveFrame);
console.log("______this.arrFrameIDS.length",this.arrFrameIDS.length)

 if(this.cycleActiveFrame < 0) {
 this.cycleActiveFrame =   this.arrFrameIDS.length-1
}

if(this.cycleActiveFrame === this.arrFrameIDS.length ) {
this.cycleActiveFrame =  0
}



this.loadFramePiece(this.arrFrameIDS[this.cycleActiveFrame])





console.log("cycleActiveFrame ",this.cycleActiveFrame);

}
// length ++
 },
 getActiveFrameMethod() {
  console.log("🤝 getActiveFrame() / newValue");

  // !! FIX - alles andere kann raus?!
  for (var i = 0; i < this.getActiveFrame.length; i++) {
    console.log("_this.getActiveFrame.length: ",this.getActiveFrame.length);
this.convertFrameIDS(this.getActiveFrame[i].frame,this.getActiveFrame[i].id) 
  } 
},
 async requestWakeLock() {
  // TODO check if wakeLock active?!
try {
   this.wakeLockRequested = await navigator.wakeLock.request('screen')
} catch (error) {
  console.error("Error wakeLock :", error);
}


},
 async requestWakeLockToRelease() {
     await wakelock.release()
},
            toggleFullScreen(event) {
 var element = document.body;

	if (event instanceof HTMLElement) {
		element = event;
	}

	var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

	element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
	document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };

	isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
      // document.documentElement.requestFullscreen({ navigationUI: 'hide' })
    },
            getFrameURL() {

let routePathURL = this.$route.path.split("/")
console.log("🔗 routePathURL ",routePathURL);

     this.urlFrameUserWallet =  routePathURL[2]
     this.urlFrameID = routePathURL[3]

console.log("this.urlFrameUserWallet ",this.urlFrameUserWallet );
console.log("this.urlFrameID ",this.urlFrameID );

if(this.urlFrameUserWallet === "" || this.urlFrameID === "") {
  console.log("🚨 404! NO FRAME OR USER FOUND IN URL.");
} else {
// ERSTMAL OF schon connected 

if(this.$route.path.indexOf('/frame/tz') !== -1) {
this.connectionToContract(this.urlFrameUserWallet)
console.log("🤝 connectionToContract(this.urlFrameUserWallet) ",this.connectionToContract(this.urlFrameUserWallet));
// NEU

}
// this.connectionToContract()
}
// v-if="imagePaths" this.queueNextImage();     
    },
  //
          showLoading() {
      console.log('showLoading')
      this.loadingInfo = true
    },
    hideLoading() {
      console.log('hideLoading')
      this.loadingInfo = false
    },
    showError() {
      console.log('showError')
      this.loadingInfo = false
    },
          mouseMove() {
      this.mouseMoving = true

      // TODO RESET TIMER?!
      //
     //  console.log('this.timer ', this.timer)
      if (this.timer === null) {
        this.timer = setInterval(() => {
          this.mouseMoving = false
          clearInterval(this.timer)
          this.timer = null
          //       console.log('this.timer ', this.timer)
        }, 6000)
        //
      }
    }, 
        convertIDframe(val) {
// 
console.log("__convertIDframe / frames ", val);

// let arrFrameIDS = [] 

try{
    this.arrFrameIDS = val.split(',')
}
catch(err){
     this.arrFrameIDS = val
}

console.log("this.arrFrameIDS.length: ",this.arrFrameIDS.length);
console.log("this.arrFrameIDS: ",this.arrFrameIDS);

// -> TODO GO NICHT FETCH alle bei multiple sondern push in array frameArray

// convert alle dann push in array, dann fetch

// for (var i = 0; i <  this.arrFrameIDS.length; i++) {


// console.log("this.arrFrameIDSConverted: ",this.arrFrameIDSConverted);
// FETCH FIRST ONE
// 
this.loadFramePiece(this.arrFrameIDS[0])
// }
//
    },
    loadFramePiece(val)  {

if( val.charAt(0) === "F") {
 this.fetchSingleTokenFrameID( val.replace('F',''),"FXHASH")
// this.arrFrameIDSConverted.push(val.replace('F',''),"FXHASH")
} 
else if( val.charAt(0) === "V") {
 this.fetchSingleTokenFrameID( val.replace('V',''),"VERSUM")
// this.arrFrameIDSConverted.push(val.replace('V',''),"VERSUM")
} 
else if( val.charAt(0) === "O") {
this.fetchSingleTokenFrameID( val.replace('O',''),"OBJKT")
// this.arrFrameIDSConverted.push(val.replace('O',''),"OBJKT")
}  
else if( val.charAt(0) === "H") {
this.fetchSingleTokenFrameID( val.replace('H',''),"HEN")
//  this.arrFrameIDSConverted.push(val.replace('H',''),"HEN")
}
    },
    // todo  CHECK load batch GRAPH QL
      async  fetchSingleTokenFrameID(valID, valPlatform) {
        console.log("🐕 fetchSingleTokenFrameID()");

  let a
  let b
  let c
  let d

 if(valPlatform === 'OBJKT' && valID.indexOf('-') !== -1) {

  let  idx = valID.split('-')
a = querySingleTokenIDobjkt
b = "SingleTokenIDobjkt"
c = idx[1]
d = idx[0]

// console.log("------------------idx[0] ",idx[0]);
   } else {
a = querySingleTokenID
b =  "SingleTokenID"
c = valID
d = valPlatform
   }
  
  const { errors, data } = await this.fetchGraphQL(a, b,{"valID":c,"valPlatform":d});

console.log("errors: ",errors);

/*
        if(valPlatform === 'OBJKT' && valID.indexOf('-') !== -1) {
          const idx = valID.split('-') //.join('');
          console.log("******* idx ",idx);
 data  = await this.fetchGraphQL(querySingleTokenIDobjkt, "SingleTokenIDobjkt",{"fa2contract":idx[1],"tokenIDcontract":idx[0]});
        } else {
  data = await this.fetchGraphQL(querySingleTokenID, "SingleTokenID",{"valID":valID,"valPlatform":valPlatform});
  }
*/
/*
  if (errors) {
    console.error(errors);
  }
*/

  // push in array?
  // const result = data.tokens[0]

//  
console.log("👋 data.tokens[0] ",data.tokens[0]);
// 

this.dataCurrentFrameToken = data.tokens[0]

   let documentArtist = "/"

if(this.dataCurrentFrameToken.artist_profile !== null && this.dataCurrentFrameToken.artist_profile!== undefined){
documentArtist = this.getUsername(this.dataCurrentFrameToken.artist_profile.alias,this.dataCurrentFrameToken.artist_address)
      } else {
documentArtist = "/"
      }

   const documentTitle = this.dataCurrentFrameToken.name
document.title = documentTitle + " by " + documentArtist + " on DIYFRAME"
    
// convertIDframe(getActiveFrame[0].frame)

// PUSH MIME TYPE + https path
// let conv = this.IPFStoHTTP(data.tokens[0].thumbnail_uri)

  // console.log("fetchSingleTokenFrameID result ",result);
 // this.arrConvertedID = result.thumbnail_uri; 
  // 

 // data.tokens[0].thumbnail_uri
 // data.tokens[0].thumbnail_uri
 // #current

 // TODO !! default = application // TODO DYN
 console.log(data.tokens[0].mime_type);

const mimeTypeToken = data.tokens[0].mime_type

console.log("mimeTypeToken", mimeTypeToken);

if(mimeTypeToken !== null && mimeTypeToken !== undefined) {
 this.mimeType = mimeTypeToken.split('/')
 this.mimeType = this.mimeType[0]
} else {
  this.mimeType = 'application'
}

 //"application" 
// tz1hGeLCUiWrj6aJBbku5CD6277SK71NSWPN

// DIRTY
if(this.mimeType === 'image' || this.mimeType === 'video' || this.mimeType === 'application') {
   console.log("KNOWN MIME TYPE");
   console.log("🙌 this.mimeType", this.mimeType);

} else {
   console.log("× UNKNOW MIME TYPE");
  this.mimeType = 'application'
}

// console.log("data.tokens[0].artifact_uri ");
// ! GEHT: MUSS SO BLEIBEN?! nicht IPFStoHTTPbutiq

// ORG: 
this.filePath = this.IPFStoHTTP(data.tokens[0].artifact_uri)

// TODO OBJKT this.filePath = this.IPFStoHTTPobjktFrame(data.tokens[0].artifact_uri)
  
 // console.log("👋 filePath ", this.filePath);
// ! FÜR DEBUG LOADING SCREEN
// this.filePath = this.IPFStoHTTP(data.tokens[1].thumbnail_uri)
  
  // ENDE: username / wallet / tz / id
 // this.document.title = "DIYFRAME by Andre Fuchs" 

  // this.imagePaths.push(data.tokens[0])
    this.loadedEntries = true

// next image
// ENDE
    // title =
    // artist = getusername
    // document.title = "Title by Artist" 
  
}, 
        getRandomInt(max) {
  return Math.floor(Math.random() * max);
},
formatPkhString(pkh) {
  return pkh.substring(0, 5) + "..." + pkh.substring(pkh.length - 4);
},
showSnackbar(val) {
this.snackbar = true
this.textSnackbar= val 

 setTimeout(function() {
      this.snackbar = false
 }, 6000);
},
         getUsername(valAlias,valTezosAddress) {
          // TODO collab

          // return "TEST"
        // console.log("valAlias ", valAlias, valTezosAddress);
if(valAlias === null || valAlias === undefined)  {
          return valTezosAddress
         } else {
          // console.log("valAlias length", valAlias );
          return valAlias
         }
         },
         getDevice() {
      // 
      console.log( 'this.$vuetify.breakpoint.name ', this.$vuetify.breakpoint.name)
      if (this.$vuetify.breakpoint.name === 'xs') {
        return false
      }
      return true
    },
    deleteFrameEntryID(id) {
     // id = "1064706" // "1117272"

     // SINGLE UND MULTIPLE UNTERSCHEIDEN - WIE?!

// FÜR MULTIPLE - [1] löscht aber alle
       // const objWithIdIndex = this.arrayMain.findIndex((obj) => obj[1].token_id === id);


/*
items.forEach((item) => item.subItems.forEach((subItem, index) => {
    if (subItem.id === subItemToBeRemovedId) {
        return item.subItems.splice(index, 1);
    }
}));
*/


     // GEHT SINGLE
  const objWithIdIndex = this.arrayMain.findIndex((obj) => obj[2].token_id === id);
  
console.log("objWithIdIndex ",objWithIdIndex);

  if (objWithIdIndex > -1) {
    // auch für multiple machen?
    this.arrayMain.splice(objWithIdIndex, 1);
  }

  // return arr;
},
    OFFdeleteFrameEntryID(valArray,valTokenID) {
// array removeItemOnce //active frame löschen?

console.log("deleteFrameEntryID ", valArray,valTokenID);

console.log("this.arrayMain.indexOf(valTokenID) ",this.arrayMain.indexOf(valTokenID));

 var index = this.arrayMain.indexOf("1117272");

  if (index > -1) {
    this.arrayMain.splice(index, 1);
  }

 
  // return valArray;

//   valArray =  valArray
    },
    logoutUser() {
      this.arrayMain = []
this.disconnectWallet();
this.$router.push('/#logout')
// TODO reset frames = null
    },
      changeURL(val) {
      // this.$router.push(val)  

// DIRTY HACK - reload everything
// TODO only relaod if new one
if(val === "Frames") {
const url = new URL('/frames', window.location.origin)
window.location.href = url.toString()
}

window.scrollTo(0, 0)
      },
            async copyToClipboardLink(mytext) {
          try {
            await navigator.clipboard.writeText(mytext);
          
//            this.showTooltip = true
          } catch ($e) {
            alert('Sorry, copy to clipboard is not available on this device');
            // this.showTooltip = false
             // this.showTooltipDonations = false
          }
        },
      async copyToClipboard(mytext) {
          try {
            await navigator.clipboard.writeText(mytext);
          
//            this.showTooltip = true
          } catch ($e) {
            alert('Sorry, copy to clipboard is not available on this device');
             this.showTooltip = false
              this.showTooltipDonations = false
          }
        },
      formatDate(val) {
return format(new Date(val), 'MMMM dd, yyyy' ) 
//  • HH:mm:ss
      },
         openFrameURL(valFrameID){
         // console.log("valFrameID ",valFrameID);

// if debug
  //    window.open("localhost:8080/frame/"+this.userAddress+"/"+valFrameID, '_blank')
if(process.env.NODE_ENV === "development") {
  return "http://192.168.2.122:8080/frame/"+this.getPkh+"/"+valFrameID
    r
// window.open("http://localhost:8080/frame/"+this.getPkh+"/"+valFrameID, '_blank')
} else {
  // window.open("https://alpha.diyframe.xyz/frame/"+this.getPkh+"/"+valFrameID, '_blank')
 return "https://alpha.diyframe.xyz/frame/"+this.getPkh+"/"+valFrameID
 // return "https://to.diyframe.xyz/"+valFrameID

}
      },
      openIPFSLINK(val){
  window.open(this.IPFStoHTTP(val), '_blank')
      },
         openLink(val) {
          // TODO change /frame/ to localhost for dev - if dev console.log("process.env.VUE_APP_NODE_ENV", process.env)
      window.open(val, '_blank')
    },

    // AKA UPDATE MEMO
      async storeOnContractAddFrame(valFrameID,valDID) {
        // RESET 
console.log("storeOnContractAddFrame valFrameID: ",valFrameID);
console.log("storeOnContractAddFrame valDID: ",valDID);
// 
// this.arrayMain = []
 // delete this.objectMain / this.objectMain.length = 0
 
// this.objectMain = null
      this.loading = true;

       await this.updateFrame({ frame: valDID, id: valFrameID});


 // RESET anti doppel thumb
 /*
this.objectMain = null
      this.loading = true;

      const payload = "" //this.frame;
      this.frame = valFrameID;
      this.toSaveDID = ""
      
      await this.addFrame(payload);
      */
      this.dialogAdd = false
      this.loading = false;

// this.getActiveFrameMethod()

//  arrayMain.push neuen rein?
// this.getFrameList()
// this.getActiveFrameMethod()
// TODO add button VIEW FRAME oder go to frames
// TODO refresh
      this.showSnackbar('Added to frame')
    },
      async storeOnContractNewFrame(valDID) {
        // RESET 

console.log("valDID: ",valDID);
// 
// this.arrayMain = []
 // delete this.objectMain / this.objectMain.length = 0
 
 // RESET anti doppel thumb
// this.objectMain = null
      this.loading = true;

      const payload = valDID //this.frame;
      this.frame = "";
      this.toSaveDID = ""
      
      await this.addFrame(payload);
      this.dialogAdd = false
      this.loading = false;

//  arrayMain.push neuen rein?
// this.getFrameList()
// this.getActiveFrameMethod()
// TODO add button VIEW FRAME oder go to frames
// TODO refresh
      this.showSnackbar('New frame created')
    },
     connect() {

      // this.doFetchEntryNFTholders()

      // TODO IN doFetchEntryNFTholders:   
      this.connectWallet();
    },

    disconnect() {
      this.disconnectWallet();
    },

    formatAddress(pkh) {
      return formatPkhString(pkh);
    },
    async handleFrameUpdate() {
      // TODO check if empty aka all entries deleted
      this.loading = true;
      await this.updateFrame({ frame: this.new_frame, id: this.id });
      this.loading = false;
      // this.toggleModal();
    },
    async toggle(){
      this.toggling = true;
      await this.toggleFrameStatus(this.id);
      this.toggling = false;
    },
    async deleteFrameFromChain(val){
      this.deleting = true;
      this.loading = true;
      await this.deleteFrame(val);
      this.deleting = false;
      this.loading = false;
      this.showSnackbar('Frame deleted')
      // TODO refresh /frames
      // TODO mainArray neu laden
    },
    openDialogAddFrame(valPlatform,valTokenID,valObjktContractAddress) {

console.log("valObjktContractAddress ",valObjktContractAddress);

      if(!this.getConnected) {
         this.connect()
      } else {
        this.dialogAdd = true //! this.dialogAdd
        this.toSavePlatform = valPlatform
        console.log("valPlatform",valPlatform);
        this.toSaveTokenID = valTokenID
        // console.log("xxxxxxxxxxxxxxxxx ", valObjktContractAddress+"-"+valTokenID);

        if(valPlatform === 'OBJKT') {
      this.toSaveDID = this.convertSingleDID(valTokenID, valPlatform) + "-" + valObjktContractAddress
       console.log("  this.toSaveDID OBJKT: ",   this.toSaveDID);
} else {
this.toSaveDID = this.convertSingleDID(valTokenID, valPlatform)
}

        }
      // ADD OBJECT TO ARRAY 1. add frame to array 2. load details add to array 3. publish to blockchain
      },
      // #current  TODO 
      addToFrame(valItem,valFrameID,valCurrentFrame,valContractAddress) {

  console.log("+addToFrame +valItem ",valItem);
  console.log("+addToFrame +valFrameID ",valFrameID);


 let playlistEntry 

//  if(valPlatform === 'OBJKT') {
 //     playlistEntry = valCurrentFrame + "," + this.toSaveDID // this.toSaveTokenID
 // } else {


      //  let shortPlatform  = this.toSavePlatform.substring(0,1) 
      //  + shortPlatform 

     playlistEntry = valCurrentFrame + "," + this.toSaveDID // this.toSaveTokenID
//  }
// TODO GO add current frame entry dazu old,new oder new,old
this.storeOnContractAddFrame(valFrameID,playlistEntry)

/*
SPÄTER REIN

      if (!this.parisArr.includes(playlistEntry)) {
      this.parisArr.push(playlistEntry);
      } else {
        alert("Already in playlist.")
      }

      console.log(this.parisArr);
*/



   //   this.dialogAdd =! this.dialogAdd


    
    },
     IPFStoHTTPobjktFrame(val) {
      // val =  this.pickTokenDisplayUri(val)  

// 
console.log("____val OBJKT ",val);

// val = val.artifact_uri
val = val.split('ipfs://').join('https://assets.objkt.media/file/assets-003/') 
val = val + '/artifact' 
     console.log("____val OBJKT generated", val)
      if (val) {
return  val
        

        // ORG: return  val.split('ipfs://').join('https://ipfs.io/ipfs/')
        // return this.ipfsResolver(val) // 
       // return val.split('ipfs://').join('https://cloudflare-ipfs.com/ipfs/')
      }
     // return '@/assets/logo.png'
 
      },
         IPFStoHTTPobjkt(val) {
      // val =  this.pickTokenDisplayUri(val)  

// 
console.log("____val OBJKT ",val);

val = val.artifact_uri
val = val.split('ipfs://').join('https://assets.objkt.media/file/assets-003/') 
val = val + '/artifact' 
     console.log("____val OBJKT generated", val)
      if (val) {
return  val
        

        // ORG: return  val.split('ipfs://').join('https://ipfs.io/ipfs/')
        // return this.ipfsResolver(val) // 
       // return val.split('ipfs://').join('https://cloudflare-ipfs.com/ipfs/')
      }
     // return '@/assets/logo.png'
 
      },
     IPFStoHTTPbutiq(val) {
      val =  this.pickTokenDisplayUri(val)  

// console.log("val PICKED ",val);

val = val.uri
      // console.log(val)
      if (val) {
// return  val.split('ipfs://').join('https://assets.objkt.media/file/assets-003/') + '/artifact'
        

        // ORG: 
        return  val.split('ipfs://').join('https://ipfs.io/ipfs/')
        // return this.ipfsResolver(val) // 
       // return val.split('ipfs://').join('https://cloudflare-ipfs.com/ipfs/')
      }
     // return '@/assets/logo.png'
 
      },
        IPFStoHTTP(val) {
  // 
  console.log("IPFStoHTTP", val)
      if (val) {
       // 
       return val.split('ipfs://').join('https://ipfs.io/ipfs/')
       // return this.ipfsResolver(val) //val.split('ipfs://').join('https://cloudflare-ipfs.com/ipfs/')
      }
     // return '@/assets/logo.png'
 
      },
             convertSingleDID(valTokenID,valPlatform) {

              // console.log("GOT valPlatform.charAt(0) ",valPlatform.charAt(0));


 if(valPlatform.charAt(0) === "F") {
 return  valTokenID = "F"+valTokenID 
 } else if(valPlatform.charAt(0) === "V") {
 return  valTokenID = "V"+valTokenID 
 } else if(valPlatform.charAt(0) === "O") {
 return  valTokenID = "O"+valTokenID 
 }else if(valPlatform.charAt(0) === "H") {
 return  valTokenID = "H"+valTokenID 
 }


// const result = 'Hiya how are you'.substring(0,8)
// this.doFetchSingleTokenID()
    },
    convertFrameIDS(val,valFrameID) {

 // if(val.indexOf(',') !== -1){
 //
  val = val.split(',')
// }
// console.log("convertFrameIDS val ",val);
// console.log("val ",val.length);

for (var i = 0; i < val.length; i++) {
  // TODO DYN
// console.log("val[i] ",val[i]);

  if(val[i].charAt(0) === "F") {
    this.doFetchSingleTokenFrameID(val[i],"FXHASH",valFrameID)
    } 
    else if(val[i].charAt(0) === "V") {
    this.doFetchSingleTokenFrameID(val[i],"VERSUM",valFrameID)
    } 
    else if(val[i].charAt(0) === "O") {
    this.doFetchSingleTokenFrameID(val[i],"OBJKT",valFrameID)
    } 
    else if(val[i].charAt(0) === "H") {
    this.doFetchSingleTokenFrameID(val[i],"HEN",valFrameID)
    }
}
    },
    //
      async  doFetchSingleTokenFrameIDFeatured(valID) {
//,valPlatform,valFrameID


// console.log("doFetchSingleTokenFrameIDFeatured valID", valID);

// default objkt damit checkj einfacher
let valPlatform = ""


if (valID.charAt(0) === "F") {
valID = valID.replace('F','')
valPlatform = "FXHASH"
 } else if(valID.charAt(0) === "V") {
valID = valID.replace('V','')
valPlatform = "VERSUM"
 }  else if(valID.charAt(0) === "O") {
valID = valID.replace('O','')
valPlatform = "OBJKT"
 }else if(valID.charAt(0) === "H") {
valID = valID.replace('H','')
valPlatform = "HEN"
 }
 //


  let a
  let b
  let c
  let d

   if(valPlatform === 'OBJKT' && valID.indexOf('-') !== -1) {

  let  idx = valID.split('-')
a = querySingleTokenIDobjkt
b = "SingleTokenIDobjkt"
c = idx[1]
d = idx[0]
// console.log("------------------valPlatform === 'OBJKT'",idx[0]);
   } else {
// console.log("------------------valPlatform === 'ELSE'");

a = querySingleTokenID
b =  "SingleTokenID"
c = valID
d = valPlatform
   }

  const { errors, data } = await this.fetchGraphQL(a, b,{"valID":c,"valPlatform":d});

  if (data) {
    // console.log("data ",data.tokens[0]);
   // console.log("data ",data)
   this.arrayFeatured.push(data.tokens[0]); 
  } else {
  
     console.error("_fetch:  errors", errors);
   // console.log("Fetched DIDs: ",this.counter);
  }
  //  
      },

      async  doFetchSingleTokenFrameID(valID,valPlatform,valFrameID) {

  if (valID.charAt(0) === "F") {
valID = valID.replace('F','')
 } else if(valID.charAt(0) === "V") {
valID = valID.replace('V','')
 }  else if(valID.charAt(0) === "O") {
valID = valID.replace('O','')
 }else if(valID.charAt(0) === "H") {
valID = valID.replace('H','')
 }
 
  let a
  let b
  let c
  let d

   if(valPlatform === 'OBJKT' && valID.indexOf('-') !== -1) {

  let  idx = valID.split('-')
a = querySingleTokenIDobjkt
b = "SingleTokenIDobjkt"
c = idx[1]
d = idx[0]

// console.log("idx[0] ",idx[0]);
   } else {
a = querySingleTokenID
b =  "SingleTokenID"
c = valID
d = valPlatform
   }
  
  const { errors, data } = await this.fetchGraphQL(a, b,{"valID":c,"valPlatform":d});

  if (data) {
     this.counter+=1
  } else {
     console.error("_fetch:  errors", errors);
  }

const diyframeFrameID = { diyframe_frame_id: valFrameID  };
const finalObject = Object.assign(diyframeFrameID, data.tokens[0]);


if(this.objectMain[valFrameID] === undefined) { 

this.objectMain[valFrameID] = {}
this.objectMain[valFrameID][0] = finalObject // data.tokens[0]

this.subMainCounter++  
} else {

this.subMainCounter++  
// let howMany =  this.subMainCounter++  
console.log("Counter _____howMany ",this.subMainCounter);

this.objectMain[valFrameID][this.subMainCounter] = finalObject // data.tokens[0]
}


if(this.counter === this.getActiveFrame.length) {
console.log("🚀 convertFrameIDS(), ALL DONE 👍");
// console.log("----", JSON.stringify(this.objectMain, null, '    '))
// GEHT
// 

console.log("this.objectMain ",this.objectMain);

// CREATE ARRAY 
for (let [key, value] of Object.entries(this.objectMain)) {

// objectMain issue?

 this.arrayMain.push(value);  
console.log("+++ arrayMain push");
}
//  console.log("----------------", JSON.stringify(this.arrayMain, null, '    '))
}
}, 
       OFFconvertMultipleDID(valFrameDIDs,valFrameID) {
        // 1. GET DIDs
let arrDIDS = valFrameDIDs.split(',')
console.log("valFrameDIDs after split: ", arrDIDS);

// 2. CREATE ARRAY WITH CUSTOM MEMO ID
// this.arrTokenID = new Array(["arrTokenID"+arrDIDS]);
// console.log("arrTokenID ",this.arrTokenID);

// var this.arrTokenID = [];
// for (var i = 1; i <= mynumber; i++) arr.push(""+i);

/*
for (var i = 0; i < arrDIDS.length; i++) {
    this.arrTokenID = ["arrTokenID"+i]
}

console.log("arrTokenID ",arrTokenID);
*/

 for (var i = 0; i < arrDIDS.length; i++) {
if(arrDIDS[i].charAt(0) === "F") {
this.doFetchSingleTokenID(arrDIDS[i].replace('F',''),"FXHASH",valFrameID)
 }
// 
 }
// const result = 'Hiya how are you'.substring(0,8)
// this.doFetchSingleTokenID()
    },
          async  doFetchTZprofile(valAccount) {
  const { errors, data } = await this.fetchGraphQL(queryTZprofile, "GetTzProfileByAccount",{"valAccount":valAccount});

  if (errors) {
    console.error(errors);
  }

// const result = data.tokens[0]
  const result = data
  // console.log("result tzprofile", data);
          },
      async  doFetchSingleTokenID(valID, valPlatform,valFrameID) {
  const { errors, data } = await this.fetchGraphQL(querySingleTokenID, "SingleTokenID",{"valID":valID,"valPlatform":valPlatform});
  if (errors) {
    console.error(errors);
  }

// const result = data.tokens[0]
  const result = data.tokens
  // console.log("result ",result);
//  console.log("valFrameID ",valFrameID);


// if(this.frameArrX[valFrameID] === undefined) {
// this.frameArrX=  new Array(["frameX"+valFrameID,result])

//  this.arrFrame =
//  this.arrFrame[valFrameID].push(result);
console.log("---------------------------")

 if(this.frameArrX[valFrameID] === undefined) {
this.frameArrX[valFrameID] = new Array(result)

} else {

  // this.frameArrX[valFrameID].push(result);  ASK DISCORD
 this.frameArrX[valFrameID].push(result); 
 console.log("this.frameArrX[valFrameID] ",this.frameArrX[valFrameID]);
 // 
 }

// console.log(" this.frameArrX ",  this.frameArrX[valFrameID]);
// 
 // return   result
}, 
      async fetchGraphQL(operationsDoc, operationName, variables) {
  const result = await fetch(
    "https://api.teztok.com/v1/graphql",
    {
      method: "POST",
      body: JSON.stringify({
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      })
    }
  );

  return await result.json();
},

async  doFetchSearch(val) {
 // const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist");
  // OLD: v1 const { errors, data } = await this.fetchGraphQL(queryAllTokensByArtist, "TokensByArtist", {"address":val});

 this.loadingCollection = true

const { errors, data } = await this.fetchGraphQL(queryAllTokensByCollector, "TokensByCollector", {"address":val});




  if (errors) {
    console.error("ERROR: ",errors);
  }
  
  const result = data.holdings
  // console.log({ result })

  this.tokenResult = result

 this.loadingCollection = false
  return result
}, 

async  doFetch(val) {
 // const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist");
  const { errors, data } = await this.fetchGraphQL(query, "TokensByArtist", {"address":val});

  if (errors) {
    console.error(errors);
  }
  const result = data.tokens
  console.log({ result })

  this.tokenResult = result

  return result
}
  },
};
</script>

<style lang="scss" scoped>
/* hide scrollbar but allow scrolling */
.hideBar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

.hideBar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.stop-scrolling {

height: 100%;

overflow: hidden;

}
.hidden ::-webkit-scrollbar {
  display: none;
}
.noWordBreak {
  word-break: keep-all;
}
  .selected {
    background-color:  #000000 !important;
  }
  .even {
    background-color: #111111 !important;
  /*   color: #ffffff;
   outline-color:white;
   outline-style: solid; */
  }

  .create::-webkit-scrollbar {
  display: none;
  overflow-y: hidden;
}
</style>
<!--
TODO
    <style>

      body {
        background-color:black;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll; 
      }
      
      body::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
      
      </style>
      -->